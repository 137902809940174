// Imports
import jwtDecode from 'jwt-decode'

export const USER_CURRENT_SET = 'USER_CURRENT_SET'
export const FETCH_USERS_BEGIN = 'FETCH_USERS_BEGIN'
export const SET_USERS = 'SET_USERS'

export function fetchUsers () {
  return dispatch => {
    dispatch({
      type: FETCH_USERS_BEGIN
    })

    return fetch(`/users`).then(function (response) {
      if (response.ok) {
        response.json().then(function (response) {
          console.log(response)
          
            dispatch({
              type: SET_USERS,
              users: response.data
            })
          
        })
      } else {
        console.log('Looks like the response wasn\'t perfect, got status', response.status)
      }
    }, function (e) {
      console.log('Fetch failed!', e)
    })
  }
}

export function postLogin (credentials) {
  return dispatch => {
    return fetch(`/user/login`, {
      method: 'post',

      body: JSON.stringify(credentials),

      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        console.log("postLogin 1: ",response)
        if (response.ok) {
          return response.json()
        }
      })
      .then(response => {
        console.log("postLogin 2: ",response)
        if (response.success) {
          const token = response.data.token

          localStorage.setItem('token', token)
          console.log(setCurrentUser(jwtDecode(token)))
          dispatch(setCurrentUser(jwtDecode(token)))
        }

        return response
      })
  }
}

// export function updateUser (user) {
//   return dispatch => {
//     return fetch(`/user/login`, {
//       method: 'post',

//       body: JSON.stringify(user),

//       headers: {
//         'Content-Type': 'application/json'
//       }
//     })
//       .then(response => {
//         console.log("updateUser 1: ",response)
//         if (response.ok) {
//           return response.json()
//         }
//       })
//       .then(response => {
//         console.log("updateUser 2: ",response)
//         if (response.success) {
//           const token = response.data.token

//           localStorage.setItem('token', token)
//           console.log(setCurrentUser(jwtDecode(token)))
//           dispatch(setCurrentUser(jwtDecode(token)))
//         }

//         return response
//       })
//   }
// }

export function postRegister (credentials) {
  console.log("POST REGISTER: ", credentials)
  return dispatch => {
    return fetch(`/user/register`, {
      method: 'post',

      body: JSON.stringify(credentials),

      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
  }
}

export function setCurrentUser (user) {
  return {
    type: USER_CURRENT_SET,
    user
  }
}

export function userLogout () {
  return dispatch => {
    localStorage.removeItem('token')

    dispatch(setCurrentUser({}))

    return {success: true}
  }
}

export function deleteUser (user) {
  const token = localStorage.getItem('token')

  return dispatch => {
    return fetch(`/user/delete`, {
      method: 'post',

      body: JSON.stringify(user),

      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token
      }
    })
      .then(response => response.json())
  }
}

export function postEdited (user) {
  const token = localStorage.getItem('token');

  return dispatch => {
    return fetch(`/user/edit`, {
      method: 'post',

      body: JSON.stringify(user),

      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token
      }
    })
      .then(response => response.json())
  }
}

export function postChange(user) {
  const token = localStorage.getItem("token");

  return dispatch => {
    return fetch(`/user/change_password`, {
      method: "post",

      body: JSON.stringify(user),

      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token
      }
    })
      .then(response => response.json())
  }
}
