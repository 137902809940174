// Imports
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles';

// UI Imports
import {Button} from '@material-ui/core'
// import { blue, red } from '@material-ui/core/colors'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import FolderIcon from '@material-ui/icons/Folder';
import FileIcon from '@material-ui/icons/InsertDriveFileOutlined';
import Box from '@material-ui/core/Box';


// App Imports
//import { postEdited } from '../../actions/user'

const styles = theme => ({
  root: {
    maxWidth: '500px',
    margin: '0 auto',
    width: '100%',
    paddingTop: '20px',
    //backgroundColor: theme.palette.background.paper,
  },
  textField: {
    marginTop: theme.spacing(1),
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
});

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  // const k = 1024;
  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

class DownloadFilesDialog extends Component {
  state = {
      name: '',
      type: 'user',
      error: '',
      openMenu: false,
      anchorEl: null,
      usersWithPermission: null,
      groupsWithPermission: null,
      totalSize: 0,
  }

  componentDidMount() {
    // console.log("this.props.open", this.props.open)
  }
 
  render () {
    
    // const { classes } = this.props
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          //maxWidth="xl"
          fullWidth={true}
        >
          {/* <DialogTitle>List of files to download</DialogTitle> */}
          <DialogTitle> 
            To download: &nbsp;
            <Box fontWeight="fontWeightBold" style={{display: "inline-block"}}>
              {formatBytes(this.props.checkedFilesSize)}
            </Box> 
            <Box style={{display: "inline-block"}}>
              &nbsp; (1 GB maximum)
            </Box> 
          </DialogTitle>
          <DialogContent>
            <List dense={false}>
              {
                this.props.checkedFilesList.length > 0 ? (
                  <Divider/>
                ) : (
                  <>
                    There is no file in the list to download
                  </>
                )
              }
              {
                this.props.checkedFilesList.map(file => {
                  if(file.mimeType === 'application/vnd.google-apps.folder'){
                    return (
                      <div key={file.id}>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <FolderIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={file.name}
                            secondary={formatBytes(file.size)}
                          />
                          <ListItemSecondaryAction>
                            <IconButton onClick={()=>{this.props.deleteFileFromList(file)}}>
                              <DeleteIcon/>
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider/>
                      </div>
                    )
                  } else {
                    return(
                      <div key={file.id}>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <FileIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={file.name}
                            secondary={formatBytes(file.size)}
                          />
                          <ListItemSecondaryAction>
                            <IconButton onClick={()=>{this.props.deleteFileFromList(file)}}>
                              <DeleteIcon/>
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider/>
                      </div>
                    )
                  }
                })
              }
            </List>           
          </DialogContent>         

            <DialogActions>
              <Button onClick={this.props.handleClose} color="secondary">
                CANCEL
              </Button>
              <Button 
                onClick={this.props.handleDownloadFiles} 
                color="secondary"
                disabled={this.props.checkedFilesList.length === 0 || this.props.checkedFilesSize > 1000000000}>
                DOWNLOAD
              </Button>
          </DialogActions>

        </Dialog>
        
      </div>
    )
  }
}

DownloadFilesDialog.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default compose(
    withStyles(styles, { withTheme: true }),
    connect(
      null,
      { }
    )
  )(DownloadFilesDialog);