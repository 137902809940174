import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FolderIcon from '@material-ui/icons/Folder';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import HomeIcon from '@material-ui/icons/Home';
//import dateFormat from 'dateformat';
import Toolbar from '@material-ui/core/Toolbar';
import Badge from '@material-ui/core/Badge';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Directory from './Directory';
import Loading from '../Loading'
import DownloadFilesDialog from './DownloadFilesDialog'
import axios from "axios";
// import { connect } from 'tls';
import { compose } from "recompose";
import { connect } from "react-redux";
// import io from "socket.io-client";
import MySnackbarContentWrapper from '../MySnackbarContent'
import Snackbar from '@material-ui/core/Snackbar';
// import streamSaver from 'streamsaver'
import * as Sentry from '@sentry/browser';
import debounce from 'lodash.debounce'
// const firebase = require("firebase");
// // Required for side-effects
// require("firebase/firestore");

// // Initialize Cloud Firestore through Firebase
// firebase.initializeApp({
//   apiKey: 'AIzaSyAciuU0uP1Y7R2ISEoA2jU7cp3c6sOotls',
//   authDomain: 'w2wintranetpt.firebaseapp.com',
//   projectId: 'w2wintranetpt'
// });

// var db = firebase.firestore();

// db.collection("USERS").doc("pol.albacar@pervaive-tech.com")
//     .onSnapshot(function(doc) {
//         console.log("Current data: ", doc.data());
//     });

// db.collection("USERS").doc("pol.albacar@pervaive-tech.com")
// .get()
// .then(doc => {
//   console.log("Current data: ", doc.data());
// })
// .catch(function(error) {
//   console.log("Error getting document:", error);
// });





// import { WritableStream } from "web-streams-polyfill/ponyfill";
// window.WebStreamsPolyfill = {
//   WritableStream: WritableStream
// }
// // const socket = io('http://localhost:5000')
// const socketEndpoint = process.env.NODE_ENV === 'production' ? 'https://w2wintranetpt.appspot.com' : 'http://localhost:5000'
// console.log("process.env.NODE_ENV: ", process.env.NODE_ENV)
// console.log("SOCKET URL: ", socketEndpoint)
// const socket = io(socketEndpoint)


const styles = theme => ({
  root: {
    width: '100%',
  },
  ListRootStyle:{
    backgroundColor: "white",
    padding: "0px"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  folderButton:{
      width: '100%',
      margin: theme.spacing(1),
      justifyContent: 'left',
  },
  linkHover:{'&:hover':{color:"#FCEA52"}},
  nested: {
    marginLeft: theme.spacing(8),
    // border: "1px solid grey",
    // //border: "1px solid grey",
    // borderRadius: "3px"
  },
  infoInLine: {
    marginRight: '200px'
  },
  infoInLineMoreSpace: {
    marginRight: '248px',
  },
  infoInLineMiddle: {
    marginRight: '248px',
  },
  grow: {
    flexGrow: 1,
  },
  infoInLineToolbar: {
    //marginRight: '192px',
    color: '#EF653A'
  },
  Toolbar: {
    backgroundColor: '#211F21',
  },
  ToolbarContentColor: {
    color: '#EF653A'
  },
  breadcrumbIcon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  downloadButton:{
    backgroundColor: "#FCEA52",
  }

});

class LandingPage extends React.Component {

  state = {
    checkedFilesList: [],
    checkedFilesFromParentsList: [],
    checkedFilesSize: 0,
    contentList: [],
    breadcrumbList: [
      {
        id: 'home',
        name: 'Home',
      }
    ],
    loading: true,
    enableSharedDialog: false,
    openDownloadFilesDialog: false,
    // currentLevel: 'home'
    marketingPermissionsUser: null,
    loadingDownload: false,
    htmlAlertDialog: false,
    valueFilter: "",
    newFilter: false
  };

  componentDidMount(){
    console.log("user -> ", this.props.user.user)
    // if(window.safari){
    //   alert("Downloads with Safari are not enabled. Please, use another browser")
    // }
    
    // if(this.props.user.user.role === "Administrator"){
    //   this.getHomeFolders();
    // } else {
    //   this.getUserPermissions();
    // }

    this.getUserPermissions();
  }

  componentDidUpdate(){
    if (this.state.valueFilter && this.state.newFilter) this.getFilesForSearch(this.state.valueFilter.toLowerCase())
    if (this.state.valueFilter == '' && this.state.newFilter) this.getHomeFolders()
  }

  getFilesForSearch = (value) => {   
    axios
    .get("/files/getFilesSearch", {
      params: {
        value: value,
        userRole: this.props.user.user.role,
        userPermissionsIds: this.state.userPermissions.map(p => p.id),
      }
    })
    .then(response => {
      if (response.data.success){
        this.setState({
          loading: false,
          breadcrumbList: [
            {
              id: 'home',
              name: 'Home',
            },
            {
              id: 'search',
              name: 'Search',
            }
          ],
          contentList: response.data.data.list,
          enableSharedDialog: true,
          newFilter: false,
          valueFilter: value
        })
      } else {
        this.setState ({
          loading: false,
          contentList: [],
          newFilter: false,
        })
      }
  
  }).catch(error => {
      console.log("ERROR: ", error)
    })
  }


  getUserPermissions = () => {
    axios
    .get("/user/get_user_permissions", {
      params: {
        email: this.props.user.user.email
      }
    })
    .then(response => {
      console.log("response user", response)
      this.setState({
        userPermissions: response.data.data.permissions, 
      }, () => {
        this.getHomeFolders()
      })
    })
    .catch(error => {
      console.log("ERROR obteniendo usuario: ", error)
      this.setState({
        snackbarMessage: "A server error ocurred. Please try again.",
        snackbarOpen: true,
        snackbarType: 'error'
      })
      Sentry.captureException(error)
    })
  }

  getHomeFolders = () => {
    axios
    .get("/drive/home_folders", {
      params: {
        userRole: this.props.user.user.role,
        userPermissionsIds: this.state.userPermissions.map(p => p.id),
      }
    })   
    .then(response => {
      console.log("response home folders", response)

      if(this.props.user.user.role === "Administrator"){

        this.setState({
          loading: false,
          breadcrumbList: [
            {
              id: 'home',
              name: 'Home',
            }
          ],
          contentList: response.data.data.list,
          enableSharedDialog: true,
          newFilter: false,
        }, () => {
          console.log("this.state.contentList: ", this.state.contentList )
        })

      } else {
        let contentList = [...response.data.data.list]

        //Aquellos que tengan permisos dentro de marketing podrán ver la carpeta raíz Marketing. En caso contrario NO.
        if(!this.state.userPermissions.some(perm => perm.allParents.includes("1Ecdx3pmg0QOgTMlhDm8PDOByk3eLnabX"))) {
          contentList = contentList.filter(dir => dir.id !== "1Ecdx3pmg0QOgTMlhDm8PDOByk3eLnabX")        
        } 
       
        contentList = contentList.filter(root => {
          if(root.id === "1Ecdx3pmg0QOgTMlhDm8PDOByk3eLnabX") return true
          else return this.state.userPermissions.some(perm => perm.id === root.id)})
          
        this.setState({
          loading: false,
          breadcrumbList: [
            {
              id: 'home',
              name: 'Home',
            }
          ],
          // contentList: response.data.data.list,
          contentList: contentList,
        }, () => {
          console.log("this.state.contentList: ", this.state.contentList )
        })

      }
    })
    .catch(error => {
      console.log("ERROR obteniendo los directorios iniciales: ", error)
      this.setState({
        snackbarMessage: "A server error ocurred. Please try again.",
        snackbarOpen: true,
        snackbarType: 'error'
      })
      Sentry.captureException(error)
    })
  }

  getAllChildren = (dir) => {
    axios
    .get("/drive/children", {
      params: {
        dir: dir,
      }
    }) 
    .then(response => {
      console.log("response new folders", response)

      this.setState({
        contentList: response.data.data.list,
        checkedFilesFromParentsList: this.computeCheckedFilesFromParentsList(response.data.data.list),
        loading: false,
      }, () => {
        console.log("this.state.contentList: ", this.state.contentList )
        console.log("ESTADO: ", this.state)
      })
    })
    .catch(error => {
      this.setState({
        snackbarMessage: "A server error ocurred. Please try again.",
        snackbarOpen: true,
        snackbarType: 'error'
      })
      Sentry.captureException(error)
    })
  }

  getMarketingChildren = () => {
    const ids = [...this.state.userPermissions].map(perm => perm.id)
    console.log("getMarketingChildren: ", ids)
    axios
    .get("/drive/marketing_children", {
      params: {
        ids: ids
      }
    }) 
    .then(response => {
      console.log("response new folders", response)

              
      // this.setState({
      //   contentList: marketingPermissionsUser,
      //   checkedFilesFromParentsList: this.computeCheckedFilesFromParentsList(marketingPermissionsUser),
      //   loading: false,
      //   //enableSharedDialog: false,
      // })

      this.setState({
        contentList: response.data.data.list,
        // marketingSize: [...response.data.data.list].reduce((valorAnterior, valorActual) => parseInt(valorAnterior)+parseInt(valorActual.size), 0),
        checkedFilesFromParentsList: this.computeCheckedFilesFromParentsList(response.data.data.list),
        loading: false,
      }, () => {
        console.log("this.state.contentList: ", this.state.contentList )
      })
    })
    .catch(error => {
      console.log("ERROR obteniendo nuevos directorios: ", error)
      Sentry.captureException(error)
      this.setState({
        snackbarMessage: "Error loading this directory. Please, try again.",
        snackbarOpen: true,
        snackbarType: 'error'
      })
    })
  }

  computeCheckedFilesFromParentsList = (contentList) => {
    //Computamos cuales son aquellos archivos o carpetas que ya deben estar seleccionados porque se ha seleccionado a su padre
    let checkedFilesIds = this.state.checkedFilesList.map(f => f.id)
    let checkedFilesFromParentsList = contentList.filter(f => {
      return f.allParents.some(parent => {
        return checkedFilesIds.indexOf(parent) >= 0
      })
    })
    return checkedFilesFromParentsList
  }

  changeDirectory = (dir, moveIn) => {

    console.log("changeDirectory", dir);
    
    //Id de Marketing. Si es descendent de Marketing sera true, sino sera false
    if (dir.id == "search") {
      //console.log("VALUE CHANGEDIRECTORY: ",this.state.valueFilter)
      this.getFilesForSearch(this.state.valueFilter)
    } else{
      const aux_enableSharedDialog = dir.allParents.some(d=>{return d==='1Ecdx3pmg0QOgTMlhDm8PDOByk3eLnabX'}) || dir.id === "1Ecdx3pmg0QOgTMlhDm8PDOByk3eLnabX"
      console.log("aux_enableSharedDialog",aux_enableSharedDialog)
      this.setState({
        loading: true,
        enableSharedDialog: aux_enableSharedDialog
      })
    }

    let aux = [...this.state.breadcrumbList]
    if(moveIn){
      //afegir directori al breadcrumb
      aux.push(dir)
      this.setState({breadcrumbList: aux})
    } 

    if(dir.id === "1Ecdx3pmg0QOgTMlhDm8PDOByk3eLnabX"){ //Marketing --> "1Ecdx3pmg0QOgTMlhDm8PDOByk3eLnabX" 
      // console.log(this.props.user.user.role)
      if(this.props.user.user.role === 'Administrator'){
        //this.setState({enableSharedDialog: true})
        this.getAllChildren(dir)
        
      } else {
        this.getMarketingChildren()
      }
    } else { // Els que no son Marketing
      //this.setState({enableSharedDialog: false})
      this.getAllChildren(dir)
    }
  }

  handleClickBreadcrumbs = (event, dir) => {
    // console.log("handleClickBreadcrumbs", dir);
    event.preventDefault();

    //suprimir fins on s'hagi clicat al breadcrumb
    let aux = [...this.state.breadcrumbList]
    for(let idx=0; idx<this.state.breadcrumbList.length-1; idx++){
      if(this.state.breadcrumbList[idx].id === dir.id){
        aux.splice(idx+1)
        // console.log("AUX: ", aux)
        this.setState({breadcrumbList: aux, valueFilter: ''})
        break;
      }
    }

    if(dir.id === 'home'){
      this.setState({
        loading: true,
        enableSharedDialog: false,
      })
      this.getHomeFolders()
    } else {
      this.changeDirectory(dir, false)
    }

  }

  handleCheckedFile = (checkedFile) => {
    
    console.log("handleCheckedFile: ", checkedFile)

    let newList = this.state.checkedFilesList;
    const filtered = this.state.checkedFilesList.filter(dir => dir.id === checkedFile.id)
    
    // console.log("filtered: ", filtered)
    if(filtered.length){ // Si el archivo ya está en la lista de seleccionados
      // DELETE File FROM LIST
      // console.log("delete from list")
      newList = newList.filter(dir => dir.id !== checkedFile.id)
      console.log(this.state.checkedFilesFromParentsList)
      this.setState({
        checkedFilesList: newList,
        checkedFilesSize: newList.reduce((valorAnterior, valorActual) => parseInt(valorAnterior)+parseInt(valorActual.size), 0)
      }, ()=>{
        this.setState({
          checkedFilesFromParentsList: this.computeCheckedFilesFromParentsList(this.state.contentList) //recalcular els archius fills que estan marcats  
        })
      })

    } else { // Si el archivo no está en la lista de seleccionados

      // ADD File TO THE LIST
      // console.log("add to list")
      newList.push(checkedFile)

      //eliminar de la lista aquellos archivos que sean hijos del que se acaba de seleccionar
      newList = newList.filter(file => {
        if(file.allParents.some(parent => parent===checkedFile.id)){
          return false
        } else {
          return true
        }
      })

      this.setState({
        checkedFilesList: newList,
        checkedFilesSize: newList.reduce((valorAnterior, valorActual) => parseInt(valorAnterior)+parseInt(valorActual.size), 0)
      }, ()=>{
        this.setState({
          checkedFilesFromParentsList: this.computeCheckedFilesFromParentsList(this.state.contentList) //recalcular els archius fills que estan marcats  
        })
      })
    }
  };

  handleOpenDownloadFilesDialog = () => {
    console.log("List of files to download: ", this.state.checkedFilesList)
    this.setState({
      openDownloadFilesDialog: true,
    })
  }

  handleCloseDownloadFilesDialog = () => {
    this.setState({openDownloadFilesDialog: false})
  }

  handleOpenHtmlAlertDialog = () => {
    this.setState({htmlAlertDialog: true})
  }

  handleCloseHtmlAlertDialog = () => {
    this.setState({htmlAlertDialog: false})
  }
  
  
  // handleDownloadFiles = () => {

  //   this.setState({
  //     loadingDownload: true,
  //     openDownloadFilesDialog: false,
  //   })
  //   console.log("Downloading files: ", this.state.checkedFilesList)

  //   const body = {
  //     checkedFilesList: this.state.checkedFilesList,
  //     user: this.props.user.user,
  //   }

  //   axios
  //   .post("/drive/download", body) 
  //   .then(response => {
  //     console.log("RESPONSE DOWNLOAD: ", response)
  //     if(response.data.success){
  //       console.log("Descarga completada correctamente", response)
  //       window.location.href = response.data.data.linkURL
  //        this.setState({
  //          loadingDownload: false,
  //          openDownloadFilesDialog: false,
  //          checkedFilesList: [],
  //          checkedFilesFromParentsList: [],
  //          checkedFilesSize: 0,
  //         })

  //     } else {
  //       console.log("ERROR1 en durante la descarga", response.data.error)
  //       this.setState({
  //         loadingDownload: false,
  //         snackbarMessage: "A download error ocurred. Please try again.",
  //         snackbarOpen: true,
  //         snackbarType: 'error'
  //       })
  //       Sentry.captureException(response.data.error)
  //     }
  //   })
  //   .catch(error => {
  //     console.log("ERROR2 en durante la descarga", error)
  //     this.setState({
  //       loadingDownload: false,
  //       snackbarMessage: "A download error ocurred. Please try again.",
  //       snackbarOpen: true,
  //       snackbarType: 'error'
  //     })
  //     Sentry.captureException(error)
  //   })

  //   // const timestamp = new Date().getTime()
  //   // const filename = 'WokToWalk_' + timestamp + '.zip'
  //   // console.log("timestamp: ", timestamp)
  //   // console.log("filename: ", filename)
  //   // const fileStream = streamSaver.createWriteStream(filename)
  //   // const token = localStorage.getItem("token");

  //   // const body = {
  //   //   checkedFilesList: this.state.checkedFilesList,
  //   //   user: this.props.user.user,
  //   // }
  //   // fetch("/drive/download", {
  //   //   method: "post",

  //   //   body: JSON.stringify(body),

  //   //   headers: {
  //   //     'Content-Type': 'application/json',
  //   //     'x-access-token': token
  //   //   }
  //   // })
  //   // .then(res => {
  //   //   console.log("res", res)
  //   //   if(res.ok){
  //   //     const readableStream = res.body
  //   //     // // more optimized
  //   //     if (window.WritableStream && readableStream.pipeTo) {

  //   //       return readableStream.pipeTo(fileStream)
  //   //         .then(() => {
  //   //           this.handleFinishDownload();
  //   //           console.log('done writing')
  //   //         })
  //   //     }
  
  //   //     const writer = fileStream.getWriter()
  
  //   //     const reader = res.body.getReader()
  //   //     const pump = () => reader.read()
  //   //       .then(res => res.done
  //   //         ? writer.close()
  //   //         : writer.write(res.value).then(pump))
  //   //     pump()
        
  //   //   } else {
  //   //     this.setState({
  //   //       snackbarMessage: "A download error ocurred. Please try again.",
  //   //       snackbarOpen: true,
  //   //       snackbarType: 'error'
  //   //     })
  //   //   }
  //   // })
  //   // .catch(error => {
  //   //   console.log("ERROR!: ", error)
  //   //   this.setState({
  //   //     snackbarMessage: "A download error ocurred. Please try again.",
  //   //     snackbarOpen: true,
  //   //     snackbarType: 'error'
  //   //   })
  //   //   Sentry.captureException(error)
  //   // })
  //   //------------------------------------------------------------------------------------------------

  // }

  handleDownloadFiles = () => {

    this.setState({
      loadingDownload: true,
      openDownloadFilesDialog: false,
    })
    console.log("Downloading files: ", this.state.checkedFilesList)

    const body = {
      checkedFilesList: this.state.checkedFilesList,
      user: this.props.user.user,
    }

    axios
    .post("/drive/download", body) 
    .then(response => {
      console.log("RESPONSE DOWNLOAD: ", response)
      if(response.data.success){
        console.log("Descarga iniciada correctamente", response)
        this.handleFinishDownloadFiles(response.data.timestamp, 0)
      } else {
        console.log("ERROR1 en durante la descarga", response.data.error)
        this.setState({
          loadingDownload: false,
          snackbarMessage: "A download error ocurred. Please try again.",
          snackbarOpen: true,
          snackbarType: 'error'
        })
        Sentry.captureException(response.data.error)
      }
    })
    .catch(error => {
      console.log("ERROR2 en durante la descarga", error)
      this.setState({
        loadingDownload: false,
        snackbarMessage: "A download error ocurred. Please try again.",
        snackbarOpen: true,
        snackbarType: 'error'
      })
      Sentry.captureException(error)
    })

  }

  handleFinishDownloadFiles = (timestamp, iteration) => {
    console.log("handleFinishDownloadFiles", timestamp, "  iteration:", iteration)
    axios.get("/drive/download_finished", {
      params:{
        timestamp: timestamp
      }
    })
    .then(response => {
      console.log("finish download response: ", response)
      if(response.data.success){
        if(!response.data.data.error){
          if(response.data.data.completed){
            console.log("Descarga completada correctamente", response)
            window.location.href = response.data.data.linkURL
            this.setState({
              loadingDownload: false,
              openDownloadFilesDialog: false,
              checkedFilesList: [],
              checkedFilesFromParentsList: [],
              checkedFilesSize: 0,
              // snackbarMessage: "Download completed successfully",
              // snackbarOpen: true,
              // snackbarType: 'success'
            })
            if(response.data.data.htmlNotification){
              this.setState({
                htmlAlertDialog: true
              })
            }
          } else {
            setTimeout(()=>{
              this.handleFinishDownloadFiles(timestamp, iteration+1)
            }, 5000);
          }
        } else {
          console.log("ERROR3 durante la descarga")
          this.setState({
            loadingDownload: false,
            snackbarMessage: "A download error ocurred. Please try again.",
            snackbarOpen: true,
            snackbarType: 'error'
          })
          Sentry.captureException("ERROR3 en durante la descarga")
        }
      } else {
        console.log("ERROR4 en durante la descarga")
        this.setState({
          loadingDownload: false,
          snackbarMessage: "A download error ocurred. Please try again.",
          snackbarOpen: true,
          snackbarType: 'error'
        })
        Sentry.captureException("ERROR4 en durante la descarga")
      }  
    })
    .catch(error => {
      console.log("ERROR5 en durante la descarga", error)
      this.setState({
        loadingDownload: false,
        snackbarMessage: "A download error ocurred. Please try again.",
        snackbarOpen: true,
        snackbarType: 'error'
      })
      Sentry.captureException(error)
    })
  }

  handleSnackbarClose = () => {
    this.setState({snackbarOpen: false})
  }

  handleChangeFilter = (event) => {
    const debounced = debounce((value) => {
      this.setState({ valueFilter: value, newFilter: true });
    }, 1500);
  
    debounced(event.target.value);
  };
  

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>

        <Toolbar className={classes.Toolbar}>

          { 
            this.state.valueFilter != '' ? (
              <Typography className={classes.ToolbarContentColor} variant="h6" noWrap>
                  SEARCH RESULTS
              </Typography>
            ) : (
              <Typography className={classes.ToolbarContentColor} variant="h6" noWrap>
                  MAIN DIRECTORIES
              </Typography>
            )
          }

          <div className={classes.grow} />
          <input style={{
            width: '200px',
            margin: 'auto',
            marginRight: '20px',
            borderRadius: '5px',
          }} placeholder="Search" type="text" onChange={this.handleChangeFilter} />
          <Badge color="secondary" badgeContent={this.state.checkedFilesList.length} className={classes.infoInLineToolbar}>
            {
              this.state.loadingDownload ? (
                <Loading/>
              ) : (
                <Button 
                variant="contained" 
                className={classes.downloadButton} 
                onClick={this.handleOpenDownloadFilesDialog}
                disabled={this.state.checkedFilesList.length === 0}
                >
                  DOWNLOAD
                </Button>
              )
            }
          </Badge>

        </Toolbar>
        <br/>

        <div style={{marginBottom: "20px"}}>
          <Breadcrumbs separator="›">
            {
              this.state.breadcrumbList.map((dir, idx) => {
                if(dir.id === 'home'){
                  return(
                    <Link color="textPrimary"
                      href=""
                      onClick={(event) => {this.handleClickBreadcrumbs(event, dir)}}
                      key={dir.id} 
                      classes={{underlineHover:classes.linkHover}}>
                      <HomeIcon 
                        className={classes.breadcrumbIcon}/>
                      {dir.name}
                    </Link>
                  )
                } else {
                  if(idx === this.state.breadcrumbList.length-1){
                    return(
                      <div style={{color: "#000000"}} key={dir.id}>
                        <FolderOpenIcon className={classes.breadcrumbIcon}/>
                        {dir.name.toLowerCase() === 'global' ? dir.autocompleteName : dir.name}
                      </div>
                    )
                  } else {
                    return(
                      <Link color="textPrimary"
                        href=""
                        onClick={(event) => {this.handleClickBreadcrumbs(event, dir)}} 
                        key={dir.id}
                        classes={{underlineHover:classes.linkHover}}>
                        <FolderIcon className={classes.breadcrumbIcon}/>
                        {dir.name.toLowerCase() === 'global' ? dir.autocompleteName : dir.name}
                      </Link>
                    )
                  }
                }
              })
            }
          </Breadcrumbs>
        </div>

        {
          this.state.loading ? (<Loading/>) : (
            <Directory
              handleCheckedFile={this.handleCheckedFile}
              contentList={this.state.contentList}
              changeDirectory={this.changeDirectory}
              enableSharedDialog={this.state.enableSharedDialog}
              user={this.props.user.user}
              checkedFilesList={this.state.checkedFilesList}
              checkedFilesFromParentsList={this.state.checkedFilesFromParentsList}
              loadingDownload={this.state.loadingDownload}
              />
          )
        }
        {
          this.state.openDownloadFilesDialog ? (
            <DownloadFilesDialog
              open={this.state.openDownloadFilesDialog}
              loadingDownload={this.state.loadingDownload}
              checkedFilesList={this.state.checkedFilesList}
              checkedFilesSize={this.state.checkedFilesSize}
              deleteFileFromList={this.handleCheckedFile}
              handleClose={this.handleCloseDownloadFilesDialog}
              handleDownloadFiles={this.handleDownloadFiles}
            />
          ) : null
        }

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
        >
          <MySnackbarContentWrapper
            onClose={null}
            variant={this.state.snackbarType}
            message={this.state.snackbarMessage}
          />
        </Snackbar>

        <Dialog
          open={this.state.htmlAlertDialog}
          onClose={this.handleCloseHtmlAlertDialog}

        >
          <DialogTitle>Caution!</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Due to Google's security restrictions regarding large files, some
              documents have not been downloaded to the zip file. 
              Instead, you will find an html document with a link to manually download the file.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseHtmlAlertDialog} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}

LandingPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function appState (state) {
  return {
    user: state.user }
}

export default compose(
  withStyles(styles),
  connect(
    appState,
    null
  )
)(LandingPage);
