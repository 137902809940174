// Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { compose } from "recompose";

// UI Imports
import { withStyles } from "@material-ui/core/styles";
import {
  Snackbar,
  Button,
  Paper,
  TextField,
  Card,
  CardContent,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Grid
} from "@material-ui/core";
import { blue, red } from "@material-ui/core/colors";
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

//import Autosuggest from 'react-autosuggest';
//import match from 'autosuggest-highlight/match';
//import parse from 'autosuggest-highlight/parse';
//import deburr from 'lodash/deburr';


// App Imports
import { postRegister } from "../../actions/user";
import AutosuggestComponent from "./AutosuggestComponent";
// import update from "immutability-helper";

const styles = theme => ({
  root: {
    maxWidth: "600px",
    margin: "0 auto",
    paddingTop: theme.spacing() * 2,
    width: "100%"
    //backgroundColor: theme.palette.background.paper,
  },
  textField: {
    marginTop: theme.spacing() * 2
  },
  buttonAñadir: {
    maxWidth: "600px",
    margin: "0 auto",
    paddingTop: theme.spacing() * 2,
    width: "100%"
    //backgroundColor: theme.palette.background.paper,
  },
  emptyFieldSnackbar: {
    textAlign: "center",
  },
});

const roles = [
  {
    value: "superusuario",
    label: "Administrador"
  },
  {
    value: "usuario",
    label: "Usuario"
  }
];

class UserRegister extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      passwordcopy: "",
      firstName: "",
      lastName: "",
      email: "",
      role: "",
      error: "",

      groupList: [],
      newGroupToList: "",
      reloadGroup : false,
      restaurantList: [],
      newRestaurantToList: "",
      reloadRestaurant : false,
      cityList: [],
      newCityToList: "",
      reloadCity : false,
      countryList: [],
      newCountryToList: "",
      reloadCountry: false,
      continentList: [],
      newContinentToList: "",
      reloadContinent: false,

      isLoading: false,
      isLoggingIn: false,
      notification: false,
      registered: false,
      emptyFieldSnackbar_Bool: false,
    };

    this.ref_AutoSugg_restaurant = React.createRef();

  }

  onSubmit(event) {
    event.preventDefault();

    let input = {};
    input.password = this.state.password;
    input.passwordcopy = this.state.passwordcopy;
    input.firstName = this.state.firstName;
    input.lastName = this.state.lastName;
    input.email = this.state.email;
    input.role = this.state.role;
    input.groups = this.state.groupList;
    input.restaurants = this.state.restaurantList;
    input.cities = this.state.cityList;
    input.countries = this.state.countryList;
    input.continents = this.state.continentList;

    if (
      input.password !== "" &&
      input.passwordcopy !== "" &&
      input.firstName !== "" &&
      input.lastName !== "" &&
      input.email !== "" &&
      input.role !== ""
    ) {

      if (input.password === input.passwordcopy ) {
        console.log("LAS CONTRASEÑAS COINCIDEN");
        this.setState({ isLoggingIn: true, isLoading: true });

        console.log("INPUT: ", input)

        this.props.postRegister(input).then(response => {
          if (response.success) {
            this.setState({
              isLoading: false,
              isLoggingIn: false,
              notification: true,
              password: "",
              firstName: "",
              lastName: "",
              email: "",
              role: "",
              error: ""
            });

            // Redirect
            this.setState({ registered: true });
          } else {
            this.setState({
              isLoading: false,
              isLoggingIn: false,
              error: response.errors[0].message,
              notification: false
            });
          }
        });
      } else {
        console.log("LAS CONTRASEÑAS NO COINCIDEN");
        this.setState({
          error: 'Passwords must match.',
          notification: false
        })
      }
    } else {
      this.setState({
        error: "Please, fill the required fields.",
        notification: false
      });
    }
  }

  onChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  componentDidUpdate() {

    if (this.state.registered) {
      setTimeout(() => {
        window.location.href = "/user_control";
      }, 1000);
    }
  }

  //#region FUNCTIONS USED TO HANDLE THE AUTOSUGGEST COMPONENT --------------------------------------------------------------------------------------
  writingNewGroup = (group) => {
    this.setState({
      newGroupToList: group,
      reloadGroup: false,
    })
  }

  addToGroupList = () => {
    if(this.state.newGroupToList !== ""){
      const newGroupToList = this.state.newGroupToList;
      this.setState(prevState => ({
        groupList: [...prevState.groupList, newGroupToList]
      }), () => {
         console.log("groupList: ", this.state.groupList)
        this.setState({
          emptyFieldSnackbar_Bool: false,
          newGroupToList: "", //Baciamos la variable que contiene el texto a añadir
          reloadGroup: true, //Para recargar el componente de Autosuggest y limpiar el texto del TextField
        }, () => {
          //console.log("STATE: ", this.state.newGroupToList)
        })
      })
    }
    else {
      this.setState({
        emptyFieldSnackbar_Bool: true,
      })
    }
  }

  writingNewRestaurant = (restaurant) => {
    this.setState({
      newRestaurantToList: restaurant,
      reloadRestaurant: false,
    })
  }

  addToRestaurantList = () => {
    if(this.state.newRestaurantToList !== ""){
      const newRestaurantToList = this.state.newRestaurantToList;
      this.setState(prevState => ({
        restaurantList: [...prevState.restaurantList, newRestaurantToList]
      }), () => {
         console.log("restaurantList: ", this.state.restaurantList)
        this.setState({
          emptyFieldSnackbar_Bool: false,
          newRestaurantToList: "", //Baciamos la variable que contiene el texto a añadir
          reloadRestaurant: true, //Para recargar el componente de Autosuggest y limpiar el texto del TextField
        }, () => {
          //console.log("STATE: ", this.state.newCityToList)
        })
      })
    }
    else {
      this.setState({
        emptyFieldSnackbar_Bool: true,
      })
    }
  }

  writingNewCity = (city) => {
    this.setState({
      newCityToList: city,
      reloadCity: false,
    })
  }

  addToCityList = () => {
    if(this.state.newCityToList !== ""){
      const newCityToList = this.state.newCityToList;
      this.setState(prevState => ({
        cityList: [...prevState.cityList, newCityToList]
      }), () => {
         console.log("cityList: ", this.state.cityList)
        this.setState({
          emptyFieldSnackbar_Bool: false,
          newCityToList: "", //Baciamos la variable que contiene el texto a añadir
          reloadCity: true, //Para recargar el componente de Autosuggest y limpiar el texto del TextField
        }, () => {
          //console.log("STATE: ", this.state.newCityToList)
        })
      })
    }
    else {
      this.setState({
        emptyFieldSnackbar_Bool: true,
      })
    }
  }

  writingNewCountry = (country) => {
    this.setState({
      newCountryToList: country,
      reloadCountry: false,
    })
  }

  addToCountryList = () => {
    if(this.state.newCountryToList !== ""){
      const newCountryToList = this.state.newCountryToList;
      this.setState(prevState => ({
        countryList: [...prevState.countryList, newCountryToList]
      }), () => {
         console.log("countryList: ", this.state.countryList)
        this.setState({
          emptyFieldSnackbar_Bool: false,
          newCountryToList: "", //Baciamos la variable que contiene el texto a añadir
          reloadCountry: true, //Para recargar el componente de Autosuggest y limpiar el texto del TextField
        }, () => {
          //console.log("STATE: ", this.state.newCountryToList)
        })
      })
    }
    else {
      this.setState({
        emptyFieldSnackbar_Bool: true,
      })
    }
  }

  writingNewContinent = (continent) => {
    this.setState({
      newContinentToList: continent,
      reloadContinent: false,
    })
  }

  addToContinentList = () => {
    if(this.state.newContinentToList !== ""){
      const newContinentToList = this.state.newContinentToList;
      this.setState(prevState => ({
        continentList: [...prevState.continentList, newContinentToList]
      }), () => {
         console.log("continentList: ", this.state.continentList)
        this.setState({
          emptyFieldSnackbar_Bool: false,
          newContinentToList: "", //Baciamos la variable que contiene el texto a añadir
          reloadContinent: true, //Para recargar el componente de Autosuggest y limpiar el texto del TextField
        }, () => {
          //console.log("STATE: ", this.state.newCountryToList)
        })
      })
    }
    else {
      this.setState({
        emptyFieldSnackbar_Bool: true,
      })
    }
  }
  // #endregion ------------------------------------------------------------------------------------------------------------------------------------------

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.state.error ? (
          <Card style={{textAlign:'center'}}>
            <CardContent style={{ color: red[500] }}>
              {this.state.error}
            </CardContent>
          </Card>
        ) : (
          ""
        )}
        {this.state.error ? <br /> : ""}
        {this.state.message ? (
          <Card style={{textAlign:'center'}}>
            <CardContent style={{ color: blue[500] }}>
              {this.state.message}
            </CardContent>
          </Card>
        ) : (
          ""
        )}
        {this.state.message ? <br /> : ""}
        {this.state.registered ? (
          ""
        ) : (
          <form id="form-tweet" onSubmit={this.onSubmit.bind(this)}>
            {/* ----------------------------------------------------------------------------------------------------------- */}
            {/* PRINCIPAL-------------------------------------------------------------------------------------------------- */}
            {/* ----------------------------------------------------------------------------------------------------------- */}
            <div className={classes.root}>
                <Paper
                  elevation={1}
                  style={{ padding: 32, display: this.state.display }}
                >
                  <h2>Add user</h2>

                  <br />
                  <TextField
                    className={classes.textField}
                    name="firstName"
                    //type="password"
                    label="First name"
                    value={this.state.firstName}
                    required={true}
                    onChange={this.onChange.bind(this)}
                    fullWidth
                  />
                  <br />
                  <TextField
                    className={classes.textField}
                    name="lastName"
                    label="Last name"
                    value={this.state.lastName}
                    required={true}
                    onChange={this.onChange.bind(this)}
                    fullWidth
                  />
                  <br />
                  <TextField
                    className={classes.textField}
                    name="email"
                    label="E-mail"
                    value={this.state.email}
                    required={true}
                    onChange={this.onChange.bind(this)}
                    fullWidth
                  />
                  <br />
                  <TextField
                    className={classes.textField}
                    name="password"
                    type="password"
                    label="Password"
                    required={true}
                    value={this.state.password}
                    onChange={this.onChange.bind(this)}
                    fullWidth
                  />
                  <br />
                  <TextField
                    className={classes.textField}
                    name="passwordcopy"
                    type="password"
                    label="Repeat password"
                    required={true}
                    value={this.state.passwordcopy}
                    onChange={this.onChange.bind(this)}
                    fullWidth
                  />
                  <br />
                  <TextField
                    className={classes.textField}
                    id="role"
                    select
                    label="Role"
                    value={this.state.role}
                    onChange={this.handleChange("role")}
                    fullWidth
                    required={true}
                  >
                    {roles.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Paper>
              </div>
            {/* ----------------------------------------------------------------------------------------------------------- */}
            {/* GRUPOS----------------------------------------------------------------------------------------------- */}
            {/* ----------------------------------------------------------------------------------------------------------- */}
            <div className={classes.root}>
              <Paper
                elevation={1}
                style={{ padding: 32, display: this.state.display }}
              >
                <h2>Groups</h2>

                <Grid container>
                    <Grid item xs={11}>
                      <AutosuggestComponent
                        placeholder="Add group"
                        writingNewField={this.writingNewGroup}
                        labelValue={this.state.reloadGroup}/>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton className={classes.addButton} aria-label="Add" onClick={this.addToGroupList}>
                        <AddIcon/>
                      </IconButton>
                    </Grid>
                </Grid>
                <div className={classes.demo}>
                <List>
                  {
                    this.state.groupList.map((group,index) => {
                      return(
                        <ListItem key={index}>
                          <ListItemText primary={group} />
                          <ListItemSecondaryAction>
                            <IconButton aria-label="Delete" onClick={() => {
                              console.log("Deleted group: ", group, ", at index: ", index)
                              let newGroupList = this.state.groupList;
                              newGroupList.splice(index,1)
                              this.setState({
                                groupList: newGroupList,
                              }, () => {
                                console.log("GroupList: ", this.state.groupList)
                              })
                            }}>
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      )
                    })
                  }
                </List>
              </div>
              </Paper>
            </div>
            {/* ----------------------------------------------------------------------------------------------------------- */}
            {/* RESTAURANTES----------------------------------------------------------------------------------------------- */}
            {/* ----------------------------------------------------------------------------------------------------------- */}
            <div className={classes.root}>
              <Paper
                elevation={1}
                style={{ padding: 32, display: this.state.display }}
              >
                <h2>Restaurants</h2>

                <Grid container>
                    <Grid item xs={11}>
                      <AutosuggestComponent
                        //ref={this.ref_AutoSugg_restaurant}
                        placeholder="Add restaurant"
                        writingNewField={this.writingNewRestaurant}
                        labelValue={this.state.reloadRestaurant}/>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton className={classes.addButton} aria-label="Add" onClick={this.addToRestaurantList}>
                        <AddIcon/>
                      </IconButton>
                    </Grid>
                </Grid>
                <div className={classes.demo}>
                <List>
                  {
                    this.state.restaurantList.map((restaurant,index) => {
                      return(
                        <ListItem key={index}>
                          <ListItemText primary={restaurant} />
                          <ListItemSecondaryAction>
                            <IconButton aria-label="Delete" onClick={() => {
                              console.log("Deleted restaurant: ", restaurant, ", at index: ", index)
                              let newRestaurantList = this.state.restaurantList;
                              newRestaurantList.splice(index,1)
                              this.setState({
                                restaurantList: newRestaurantList,
                              }, () => {
                                console.log("RestaurantList: ", this.state.restaurantList)
                              })

                            }}>
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      )
                    })
                  }
                </List>
              </div>
              </Paper>
            </div>
            {/* ----------------------------------------------------------------------------------------------------------- */}
            {/* CIUDADES--------------------------------------------------------------------------------------------------- */}
            {/* ----------------------------------------------------------------------------------------------------------- */}
            <div className={classes.root}>
              <Paper
                elevation={1}
                style={{ padding: 32, display: this.state.display }}
              >
                <h2>Cities</h2>

                <Grid container>
                    <Grid item xs={11}>
                      <AutosuggestComponent
                        placeholder="Add city"
                        writingNewField={this.writingNewCity}
                        labelValue={this.state.reloadCity}/>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton className={classes.addButton} aria-label="Add" onClick={this.addToCityList}>
                        <AddIcon/>
                      </IconButton>
                    </Grid>
                </Grid>

                <div className={classes.demo}>
                <List>
                  {
                    this.state.cityList.map((city,index) => {
                      return(
                        <ListItem key={index}>
                          <ListItemText primary={city} />
                          <ListItemSecondaryAction>
                            <IconButton aria-label="Delete" onClick={() => {
                              console.log("Deleted city: ", city, ", at index: ", index)
                              let newCityList = this.state.cityList;
                              newCityList.splice(index,1)
                              this.setState({
                                cityList: newCityList,
                              }, () => {
                                console.log("cityList: ", this.state.cityList)
                              })

                            }}>
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      )
                    })
                  }
                </List>
                </div>
              </Paper>
            </div>
            {/* ----------------------------------------------------------------------------------------------------------- */}
            {/* PAISES----------------------------------------------------------------------------------------------------- */}
            {/* ----------------------------------------------------------------------------------------------------------- */}
            <div className={classes.root}>
              <Paper
                elevation={1}
                style={{ padding: 32, display: this.state.display }}
              >
                <h2>Countries</h2>

                <Grid container>
                    <Grid item xs={11}>
                      <AutosuggestComponent
                        placeholder="Add country"
                        writingNewField={this.writingNewCountry}
                        labelValue={this.state.reloadCountry}/>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton className={classes.addButton} aria-label="Add" onClick={this.addToCountryList}>
                        <AddIcon/>
                      </IconButton>
                    </Grid>
                </Grid>

                <div className={classes.demo}>
                <List>
                  {
                    this.state.countryList.map((country,index) => {
                      return(
                        <ListItem key={index}>
                          <ListItemText primary={country} />
                          <ListItemSecondaryAction>
                            <IconButton aria-label="Delete" onClick={() => {
                              console.log("Deleted country: ", country, ", at index: ", index)
                              let newCountryList = this.state.countryList;
                              newCountryList.splice(index,1)
                              this.setState({
                                countryList: newCountryList,
                              }, () => {
                                console.log("countryList: ", this.state.countryList)
                              })

                            }}>
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      )
                    })
                  }
                </List>
              </div>
              </Paper>
            </div>
            {/* ----------------------------------------------------------------------------------------------------------- */}
            {/* CONTINENTES------------------------------------------------------------------------------------------------ */}
            {/* ----------------------------------------------------------------------------------------------------------- */}
            <div className={classes.root}>
              <Paper
                elevation={1}
                style={{ padding: 32, display: this.state.display }}
              >
                <h2>Continents</h2>

                <Grid container>
                    <Grid item xs={11}>
                      <AutosuggestComponent
                        placeholder="Add continent"
                        writingNewField={this.writingNewContinent}
                        labelValue={this.state.reloadContinent}/>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton className={classes.addButton} aria-label="Add" onClick={this.addToContinentList}>
                        <AddIcon/>
                      </IconButton>
                    </Grid>
                </Grid>

                <div className={classes.demo}>
                <List>
                  {
                    this.state.continentList.map((continent,index) => {
                      return(
                        <ListItem key={index}>
                          <ListItemText primary={continent} />
                          <ListItemSecondaryAction>
                            <IconButton aria-label="Delete" onClick={() => {
                              console.log("Deleted continent: ", continent, ", at index: ", index)
                              let newContinentList = this.state.continentList;
                              newContinentList.splice(index,1)
                              this.setState({
                                continentList: newContinentList,
                              }, () => {
                                console.log("CityList: ", this.state.continentList)
                              })

                            }}>
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      )
                    })
                  }
                </List>
              </div>
              </Paper>
            </div>
            {/* ----------------------------------------------------------------------------------------------------------- */}
            {/* ----------------------------------------------------------------------------------------------------------- */}
            {/* ----------------------------------------------------------------------------------------------------------- */}
            <div className={classes.root}>
              <Button variant="contained" type="submit" color="primary" className={classes.buttonAñadir}>
                Add
              </Button>
            </div>
          </form>
        )}
        <Snackbar
          open={this.state.isLoggingIn}
          message="Creando usuario..."
          autoHideDuration={1000}
        />

        <Snackbar
          open={this.state.notification}
          message="Usuario creado correctamente."
          autoHideDuration={4000}
        />

        <Snackbar
          className={classes.emptyFieldSnackbar}
          open={this.state.emptyFieldSnackbar_Bool}
          message="Introduce un campo correcto"
          autoHideDuration={1000}
        />
      </div>
    );
  }
}

UserRegister.propTypes = {
  postRegister: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(
    null,
    { postRegister }
  )
)(UserRegister);
