// Imports
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
import axios from "axios";
import * as Sentry from '@sentry/browser';

// UI Imports
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from "@material-ui/icons/Delete";
import Button from '@material-ui/core/Button';
import FolderIcon from '@material-ui/icons/Folder';
import PersonIcon from '@material-ui/icons/Person';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';
import Checkbox from '@material-ui/core/Checkbox';

// App Imports
import MySnackbarContentWrapper from '../MySnackbarContent'
import Loading from '../Loading'
import AutosuggestComponent from './AutosuggestComponent'
import { compose } from "recompose";
import { connect } from "react-redux";
import { fetchUsers } from "../../actions/user"

const styles = theme => ({
  root: {
    maxWidth: '500px',
    margin: '0 auto',
    width: '100%',
    paddingTop: '20px',
    //backgroundColor: theme.palette.background.paper,
  },
  textField: {
    marginTop: 20,
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
});

class PermissionsGroupDialog extends Component {
  state = {
    newPermissionList: [],
    newPermission: "",
    users: [],
    levels: [],
    isLoading: true,
    valueFilter: '',
    newValueFilter: false
  }

  componentDidMount() {
    console.log("group to edit: ", this.props.group_to_edit_permissions)
    console.log("PROPS PERMISSIONLIST: ", this.props.permissionList)

    this.setState({
      newPermissionList: [...this.props.permissionList],
    })

    if(this.props.permissionType === 'users'){
      this.getUsers()
    } else {
      this.getLevels()
    }
  }

  componentDidUpdate(){
    if (this.state.valueFilter != '' && this.state.newValueFilter) this.getFilterUser()
    if (this.state.valueFilter == '' && this.state.newValueFilter) this.getUsers()
  }

  getLevels = () => {
    // DRIVE FOLDERS AND FILES ------------------------------------------------------------------------------------------------
    console.log("getLevels: ", this.props.permissionType)
    axios
    .get("/drive/levels", {
      params: {
        permissionType: this.props.permissionType,
        id: this.props.group_to_edit_permissions[0],
        userOrGroup: "group",
      }
    })
    .then(response_levels => {
      console.log("response_levels: ", response_levels)
      if(response_levels.data.success){
        this.setState({
          levels: response_levels.data.data.list,
          isLoading: false,
        })
      } else{
        console.log("GROUP ERROR 1: ", response_levels.data.error.message)
        this.props.handleSnackbarOpen('error', 'There was a problem connecting to the server. Please try again')
        Sentry.captureException(response_levels.data.error.message)
        this.props.handleCloseDialog()
      }
    })
    .catch(err => {
      console.log("ERROR obteniendo el objeto con los niveles para construir el autosuggest", err)
      Sentry.captureException(err)
      this.props.handleSnackbarOpen('error', 'There was a problem connecting to the server. Please try again')
      this.props.handleCloseDialog()
    })
    // DRIVE FOLDERS AND FILES ------------------------------------------------------------------------------------------------
  }

  getUsers = () => {
    // USERS ------------------------------------------------------------------------------------------------
    axios
      .get("/users")
      .then(response_users => {
        console.log("response_users: ", response_users)
        this.setState({
          users: response_users.data.data,
          isLoading: false,
          newValueFilter: false
        })
      })
      .catch(error => {
        console.log("ERROR obteniendo la lista de usuarios: ", error)
        Sentry.captureException(error)
        this.props.handleSnackbarOpen('error', 'There was a problem connecting to the server. Please try again')
      })
    // USERS ------------------------------------------------------------------------------------------------
  }

  handleChangeFilter = (event) => {
    this.setState({valueFilter: event.target.value, newValueFilter: true});
  }

  getFilterUser = () => {
    // FILTER USERS ------------------------------------------------------------------------------------------------
    axios
    .get("/users")
    .then(response_users => {
      let filterUsers = []
      for (let step = 0; step < response_users.data.data.length; step++) {
          if (response_users.data.data[step]['email'].includes(this.state.valueFilter)){
              filterUsers.push(response_users.data.data[step])
          }
      }      
      this.setState({
        users: filterUsers,
        isLoading: false,
        newValueFilter: false
      })
    })
    .catch(error => {
      console.log("ERROR obteniendo la lista de usuarios: ", error)
      Sentry.captureException(error)
      this.props.handleSnackbarOpen('error', 'There was a problem connecting to the server. Please try again')
    })
    
    // FILTER USERS ------------------------------------------------------------------------------------------------
  }

  handleFinishEditPermissions = () => {
    //console.log("OLD LIST: ", this.props.permissionList)
    //console.log("NEW LIST: ", this.state.newPermissionList)

    this.setState({isLoading: true})

    let input = {
      groupName: this.props.group_to_edit_permissions[0],
      permissionType: this.props.permissionType,
      list: this.state.newPermissionList,
      old_list: this.props.permissionList,
      group_id: this.props.group_to_edit_permissions[7],
    }

    axios.post("/groups/change_permissions", input)
      .then(response => {
        console.log("CHANGE PERMISSIONS RESPONSE: ", response)
        if (response.data.success) {
          this.setState({isLoading: false});

          this.props.handleSnackbarOpen("success", "Group permissions updated correctly")
          this.props.handleCloseDialog()
          //this.props.reloadParent()

        } else {
          console.log("ERROR: ", response.data.error.message)
          Sentry.captureException(response.data.error.message)
          this.setState({
            isLoading: false,
            snackbarOpen: true,
            snackbarMessage: response.data.error.message,
            snackbarType: 'error',
          })
        }
      })
      .catch(error => {
        console.log("ERROR: ", error)
        Sentry.captureException(error)
        this.setState({
          isLoading: false,
          snackbarOpen: true,
          snackbarMessage: 'There was a problem connecting to the server. Please try again',
          snackbarType: 'error',
        })
      })
  }

  onChange (event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleAddNewPermission = (permission) => {

    console.log("ANTES: ", this.props.permissionList)

    console.log("new permission: ", permission)

    let aux = [...this.state.newPermissionList];
    const toPush = this.props.permissionType === 'users' ? permission.label : permission;
    aux.push(toPush)    
    this.setState({
      newPermissionList: aux,
    }, ()=>{
      console.log("DESPUÉS: ", this.state.newPermissionList)
    })
  }

  handleDeletePermissions_arrayStrings = (permission) => {
    console.log("handleDeletePermissions_arrayStrings")
    let array = this.state.newPermissionList;
    console.log("array: ", array)
    const index = array.indexOf(permission)
    if(index > -1){
      array.splice(index,1)
      console.log("array: ", array)
      this.setState({
        newPermissionList: array
      })
    }
  }

  handleDeletePermissions_arrayObjects = (permission) => {
    console.log("handleDeletePermissions_arrayObjects")
    let array = this.state.newPermissionList;
    console.log("array before delete: ", array)
    array = array.filter(p => p.id !== permission.id); //devuelve todos los permisos que tienen id diferente al que se acaba de borrar
    console.log("array after delete: ", array)
    this.setState({
      newPermissionList: array
    })
  }

  handleSnackbarOpen = (type, message) => {
    //console.log("PARENT SNACKBAR OPEN")
    this.setState({
      snackbarMessage: message,
      snackbarType: type,
      snackbarOpen: true,
    })
  }

  handleSnackbarClose = () => {
    this.setState({snackbarOpen: false})
  }

  handleToggle_object = (element) => {

    if(this.state.newPermissionList.some(el => el.id === element.id)){
      //el permiso ya está en la lista --> eliminar de la lista
      let newList = this.state.newPermissionList.filter(el => el.id !== element.id)
      this.setState({
        newPermissionList: newList
      })
    } else{
      //el permiso no está en la lista --> añadir a la lista
      let newList = [...this.state.newPermissionList]
      newList.push(element)
      this.setState({
        newPermissionList: newList
      })
    }
    
  };

  handleToggle_string = (element) => {
    let array = [...this.state.newPermissionList];
    console.log("array: ", array)
    const index = array.indexOf(element)
    if(index > -1){
      console.log("delte")
      array.splice(index,1)
      console.log("array: ", array)
      this.setState({
        newPermissionList: array
      })
    } else {
      console.log("add")
      array.push(element)
      this.setState({
        newPermissionList: array
      })
    }
    
  };

  mapTitle(permissionType){
    switch(permissionType){
      case "users":
        return "ASSIGNED USERS"
        break;
      case "global":
        return "HOME PERMISSIONS"
        break;
      case "continents":
      case "countries":
      case "cities":
      case "restaurants":
        return permissionType.toUpperCase() + " PERMISSIONS"
        break;
    }
  }

  render () {
    // const { classes } = this.props

    let dialogContent = [];
    if(!this.state.isLoading){
      switch(this.props.permissionType){
        //---------------------------------------------------------------------------------------------------------------
        case "users":
          console.log("users")
          this.state.users.forEach(user => {
            dialogContent.push(
              <>
                <div key={user.email}>
                  <Divider/>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <PersonIcon/>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={user.email}
                      />
                    <ListItemSecondaryAction>
                    <Checkbox
                      onChange={()=>{this.handleToggle_string(user.email)}}
                      checked={this.state.newPermissionList.indexOf(user.email) > -1}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                </div>
              </>
            )
          })
          break;
        //---------------------------------------------------------------------------------------------------------------        
        case "global":
        case "continents":
        case "countries":
          this.state.levels.forEach(permission => {
            if(permission.id !== "1Ecdx3pmg0QOgTMlhDm8PDOByk3eLnabX"){
              dialogContent.push(
                <>
                  <div key={permission.id}>
                    <Divider/>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <FolderIcon/>
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={permission.autocompleteName}
                      />
                      <ListItemSecondaryAction>
                      <Checkbox
                        onChange={()=>{this.handleToggle_object(permission)}}
                        checked={this.state.newPermissionList.some(el => el.id === permission.id)}
                      />
                      </ListItemSecondaryAction>
                    </ListItem>
                  </div>
                </>
              )
            }
          })
          break;
        //---------------------------------------------------------------------------------------------------------------  
        case "cities":
        case "restaurants":
          this.state.newPermissionList.forEach(permission => {
            dialogContent.push(
              <div key={permission.label}>
                <Divider/>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <FolderIcon/>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={permission.autocompleteName}
                  />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => {this.handleDeletePermissions_arrayObjects(permission)}}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </div>
            )
          })

          dialogContent.push(
            <>
              <Divider/>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <FolderIcon/>
                  </Avatar>
                </ListItemAvatar>
                <AutosuggestComponent
                  // handleAddNewPermission={this.handleAddNewPermission}
                  // handleChangeAutosuggest={this.handleChangeAutosuggest}
                  users={this.state.users}
                  levels={this.state.levels}
                  permissionType={this.props.permissionType}
                  addNewPermission={this.handleAddNewPermission}
                  // user_to_edit_permissions={this.props.user_to_edit_permissions}
                  />
              </ListItem>
            </>
          )
  
          break;
        //---------------------------------------------------------------------------------------------------------------
      }
    }

    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleCloseDialog}
          //maxWidth="xl"
          fullWidth={true}
        >
          <DialogTitle>
            {this.mapTitle(this.props.permissionType)}
          </DialogTitle>
          <input style={{
            width: '250px',
            margin: 'auto',
          }} placeholder="Search" type="text" value={this.state.valueFilter} onChange={this.handleChangeFilter} />
          {
            this.state.isLoading ? 
            (
              <div style={{padding: "40px 40px 40px 40px"}}>
                <Loading color="#EF653A"/>
              </div>
            ) : ( 
              <>
                <DialogContent>
                  <List dense={false}>
                    {
                      dialogContent.map((content, idx) => <div key={idx}>{content}</div>)
                    }
                  </List>

                </DialogContent>
                <DialogActions>
                  <Button onClick={this.props.handleCloseDialog} color="secondary">
                    CANCEL
                  </Button>
                  <Button color="secondary" onClick={this.handleFinishEditPermissions}>
                    SAVE
                  </Button>
                </DialogActions>
              </>
            )
          }

          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={this.state.snackbarOpen}
            autoHideDuration={6000}
            onClose={this.handleSnackbarClose}
          >
            <MySnackbarContentWrapper
              onClose={this.handleSnackbarClose}
              variant={this.state.snackbarType}
              message={this.state.snackbarMessage}
            />
          </Snackbar>

        </Dialog>
        
      </div>
    )
  }
}

PermissionsGroupDialog.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default compose(withStyles(styles, { withTheme: true }), connect(null, {fetchUsers}))(PermissionsGroupDialog);
