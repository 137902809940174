import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';

// App Imports
import Loading from '../Loading'
import GroupList from './GroupList'
import axios from "axios";
import MySnackbarContentWrapper from '../MySnackbarContent'
import Snackbar from '@material-ui/core/Snackbar';

class GroupListContainer extends Component {

  state = {
    groups: [],
    loading: true,
  }

  componentDidMount () {
    this.getGroups()
  }

  reloadParent = () => {
    this.setState({loading:true})
    console.log("RELOAD PARENT")
    this.getGroups()    
  }

  getGroups(){
    axios
      .get("/groups/list")
      .then(response => {
        console.log("response: ", response)
        this.setState({
          groups: response.data.data,
          loading: false,
        })
      })
      .catch(error => {
        console.log("ERROR: ", error)
        Sentry.captureException(error)
        this.handleSnackbarOpen('error', 'There was a problem connecting to the server. Please try again')
      })
  }

  handleSnackbarOpen = (type, message) => {
    //console.log("PARENT SNACKBAR OPEN")
    this.setState({
      snackbarMessage: message,
      snackbarType: type,
      snackbarOpen: true,
    })
  }

  handleSnackbarClose = () => {
    this.setState({snackbarOpen: false})
  }

  render () {
    return (
      <div>
        {this.state.loading ? <Loading/> : <GroupList 
                                              user={this.props.user} 
                                              groups={this.state.groups}
                                              levels={this.state.levels}
                                              users={this.state.users}
                                              handleSnackbarOpen={this.handleSnackbarOpen}
                                              reloadParent={this.reloadParent}
                                              />}

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.handleSnackbarClose}
            variant={this.state.snackbarType}
            message={this.state.snackbarMessage}
          />
        </Snackbar>
      </div>
    )
  }
}

GroupListContainer.propTypes = {
  user: PropTypes.object.isRequired,
}

function GroupListState (state) {
  return {
    user: state.user,
  }
}


export default connect(GroupListState, {})(GroupListContainer);
