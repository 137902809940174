import React from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import {Redirect} from 'react-router-dom'

import Layout from "./components/Layout";
import LandingPage from "./components/LandingPage/LandingPage";
// import LandingPage5 from "./components/LandingPage/LandingPage5";
// import LandingPage2 from "./components/LandingPage/LandingPage2";
// import LandingPage3 from "./components/LandingPage/LandingPage3";
// import LandingPage4 from "./components/LandingPage/LandingPage4";
// import LandingPage1 from "./components/LandingPage/LandingPage1";

import UserListContainer from "./components/user/UserListContainer";
import GroupListContainer from "./components/group/GroupListContainer";

import UserLogin from "./components/user/Login";
import Register from "./components/user/Register";
import sentMails from "./components/MailConfig/sentMails"
import donwloadesMade from "./components/MailConfig/downloadMade"
import ChangePassword from "./components/user/ChangePassword";
//import AddUser from "./components/user/AddUser";
import MailConfig from "./components/MailConfig/MailConfig";
//import AddNewUser from "./components/user/AddNewUser";


// import PageNotFound from "./components/PageNotFound";

import 'bootstrap/dist/css/bootstrap.min.css';

const App = (props) => (
  <Layout>
    {!props.user.isAuthenticated ? (
      <UserLogin />
    ) : (
      <div
        style={{
          // height: "calc(100vh - 96px)",
          width: "100%",
          overflowX: "hidden",
          overflowY: "auto",
          padding: "20px"
        }}
      >
        <Switch>

          <Route path="/" exact component={LandingPage} />

          {/* {
            props.user.user.role === 'Administrator' ? (
              <Route path="/change_password_admin" exact component={ChangePassword} />
            ) : null} */}

          {
            props.user.user.role === 'Administrator' ? (
              <Route path="/user_control" exact component={UserListContainer} />
            ) : null}

          {
            props.user.user.role === 'Administrator' ? (
              <Route path="/user_control/registrar" component={Register} />
            ) : null}

          {
            props.user.user.role === 'Administrator' ? (
              <Route path="/group_control" component={GroupListContainer} />
            ) : null}

          {
            props.user.user.role === 'Administrator' ? (
              <Route path="/mail_configuration" component={MailConfig} />
            ) : null}   

          {
            props.user.user.role === 'Administrator' ? (
              <Route path="/sentMails" component={sentMails} />
            ) : null} 

          {
            props.user.user.role === 'Administrator' ? (
              <Route path="/downloadedFiles" component={donwloadesMade} />
            ) : null}  
          
          {/* <Route component={PageNotFound} /> */}
          <Route render={()=><Redirect to="/"/>} />

        </Switch>
      </div>
    )}
  </Layout>
);

function appState (state) {
  return {
    user: state.user,
  }
}

export default withRouter(connect(appState)(App));
