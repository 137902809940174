// Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import * as Sentry from '@sentry/browser';

// UI Imports
import {
  Snackbar,
  Button,
  Paper,
  TextField,
} from "@material-ui/core";
import MySnackbarContentWrapper from '../MySnackbarContent'

// App Imports
import { postLogin, postRegister } from "../../actions/user";
const IMAGE_BASE_URL = "/images/homebackground_lighter.jpg";

const styles = theme => ({
  root: {
    maxWidth: "500px",
    width: "100%",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    //margin: "0 auto",

    //paddingTop: theme.spacing() * 4,
    //position: 'fixed',
  },
  textField: {
    marginTop: theme.spacing() * 2
  },
  iniciarSesionButton: {
    marginTop: theme.spacing() * 3,
    backgroundColor: '#e74e2d',
    color: '#191719',
    fontSize: "18px",
    fontFamily: 'VanCondensedBold',
  },
  title: {
    backgroundColor: '#191719',
    color: '#e74e2d',
    padding: "10px 0",
    textAlign: "center",
    fontSize: "40px",
    fontFamily: 'VanCondensedBold',
  },
});

const backgroundStyle = {
  marginTop: '-10px',
  backgroundRepeat: 'no-repeat',
  //backgroundAttachment: 'fixed',
  backgroundAttachment: 'scroll',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  width: "100%",
  height: "100vh",
  backgroundImage: `url(${IMAGE_BASE_URL})`,
  backgroundColor: 'rgba(53,56,91,0.5)',
}

class UserLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      isLoading: false,
      isLoggingIn: false,
      notification: false,
      logged: false,
      display: "content"
    };
  }

  componentDidMount() {

  }

  onSubmit(event) {
    event.preventDefault();

    let input = {};
    input.email = this.state.email;
    input.password = this.state.password;

    //console.log(input.password);

    if (input.email !== "" || input.password !== "") {
      this.setState({ isLoggingIn: true, isLoading: true });
      console.log("enviant logging");
      console.log(input);
      this.props.postLogin(input).then(response => {
        console.log(response);
        if (response.success) {

          this.handleSnackbarOpen('success', 'Logging in')

          this.setState({ display: "none" });
          // Redirect
          setTimeout(() => {
            this.setState({ logged: true });
          }, 1000);
        } else {
          this.handleSnackbarOpen('error', response.error.message)
        }
      });
    } else {
      this.handleSnackbarOpen('error', "Please, enter a username and password")
    }
  }

  handleSnackbarOpen = (type, message) => {
    console.log("PARENT SNACKBAR OPEN")
    this.setState({
      snackbarMessage: message,
      snackbarType: type,
      snackbarOpen: true,
    })
  }

  handleSnackbarClose = () => {
    this.setState({snackbarOpen: false})
  }

  onChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div style={backgroundStyle}>
        <div className={classes.root}>
          <form id="form-tweet" onSubmit={this.onSubmit.bind(this)}>
            <Paper
              elevation={1}
              style={{ padding: 32, display: this.state.display }}
            >
              <h2 className={classes.title}>WOK TO WALK INTRANET</h2>

              <TextField
                className={classes.textField}
                name="email"
                label="Email"
                value={this.state.email}
                required={true}
                onChange={this.onChange.bind(this)}
                fullWidth
              />
              <TextField
                className={classes.textField}
                name="password"
                type="password"
                label="Password"
                required={true}
                value={this.state.password}
                onChange={this.onChange.bind(this)}
                fullWidth
              />

              <Button className={classes.iniciarSesionButton} variant="contained" type="submit" color="primary">
                LOGIN
              </Button>
            </Paper>
          </form>

          {this.state.logged ? <Redirect to="/" /> : ""}
        </div>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.handleSnackbarClose}
            variant={this.state.snackbarType}
            message={this.state.snackbarMessage}
          />
        </Snackbar>

      </div>
    );
  }
}

UserLogin.propTypes = {
  postLogin: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

// UserLogin.contextTypes = {
//   router: PropTypes.object.isRequired
// };

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(
    null,
    { postLogin, postRegister }
  )
)(UserLogin);
