// Imports
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';

// UI Imports
import {Button, TextField, MenuItem } from '@material-ui/core'
// import { blue, red } from '@material-ui/core/colors'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import axios from "axios";
import Loading from '../Loading'


// App Imports
//import { postEdited } from '../../actions/user'

const styles = theme => ({
  root: {
    maxWidth: '500px',
    margin: '0 auto',
    width: '100%',
    paddingTop: '20px',
    //backgroundColor: theme.palette.background.paper,
  },
  textField: {
    marginTop: theme.spacing(1),
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
});


class SharedDialog extends Component {
  state = {
      name: '',
      type: 'user',
      openMenu: false,
      anchorEl: null,
      usersWithPermission: null,
      groupsWithPermission: null,
  }

  componentDidMount() {
    // console.log("this.props.open", this.props.open)
    this.generateWhoHasPermission()
  }

  generateWhoHasPermission = () => {
    console.log(" generateWhoHasPermission -> ", this.props.dir)

    axios
    // .get("/drive/who_has_permission", {
    .get("/drive/who_has_permission", {
      params: {
        dir: this.props.dir
      }
    })
    .then(response => {
      console.log("response user", response)
      this.setState({
        usersWithPermission: response.data.data.usersWithPermission, 
        groupsWithPermission: response.data.data.groupsWithPermission, 
      })
    })
    .catch(error => {
      console.log("ERROR obteniendo los usuarios que tienen permiso: ", error)
      Sentry.captureException(error)
    })  
  }

  onSubmit (event) {

  }

  onChange (event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleOpenMenu = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    })
  }

  handleMenuSelect = (type) => {
    this.setState({
      type: type,
      anchorEl: null,
    })
  }

  handleCloseMenu = () => {
    this.setState({
      anchor: null,
    })
  }

  render () {
    
    // const { classes } = this.props

    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          //maxWidth="xl"
          fullWidth={true}
        >
          <DialogTitle>Sharing permissions in {this.props.dir.name} folder</DialogTitle>
          {
            !this.state.usersWithPermission || !this.state.groupsWithPermission ? (
              <Loading color="#EF653A"/>
            ) : (
              <DialogContent>
                <List dense={false}>
                  {
                    (this.state.usersWithPermission.length > 0 || this.state.groupsWithPermission.length > 0) ? (
                      <Divider/>
                    ) : ( 
                      <>
                        Nobody has access to this folder
                      </>
                    )
                  }
                    {
                      this.state.usersWithPermission.map(user => {
                        return (
                          <div key={user.email}>
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar>
                                  <PersonIcon />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={user.firstName + " " + user.lastName}
                                secondary={user.email}
                              />
                              {/* <ListItemSecondaryAction>
                                <IconButton>
                                  <DeleteIcon />
                                </IconButton>
                              </ListItemSecondaryAction> */}
                            </ListItem>
                            <Divider/>
                          </div>
                        )
                      })
                    }
                    {
                      this.state.groupsWithPermission.map(group => {
                        return (
                          <div key={group.groupName}>
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar>
                                  <GroupIcon />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={group.groupName}
                                secondary={group.users.length + " users"}
                              />
                              {/* <ListItemSecondaryAction>
                                <IconButton>
                                  <DeleteIcon />
                                </IconButton>
                              </ListItemSecondaryAction> */}
                            </ListItem>
                            <Divider/>
                          </div>
                        )
                      })
                    }
                  {/* <ListItem>
                    <ListItemAvatar>
                      <>
                        <IconButton onClick={this.handleOpenMenu}>
                          {
                            this.state.type === "user" ? (
                              <Avatar>
                                <PersonIcon />
                              </Avatar>
                            ) : (
                              <Avatar>
                                <GroupIcon />
                              </Avatar>
                            )
                          }

                        </IconButton>
                        <Menu
                          keepMounted
                          open={Boolean(this.state.anchorEl)}
                          onClose={this.handleCloseMenu}
                          anchorEl={this.state.anchorEl}>
                          <MenuItem onClick={() => {this.handleMenuSelect('user')}}>
                            <Avatar>
                              <PersonIcon />
                            </Avatar>
                          </MenuItem>
                          <MenuItem onClick={() => {this.handleMenuSelect('group')}}>
                            <Avatar>
                              <GroupIcon />
                            </Avatar>
                          </MenuItem>
                        </Menu>
                      </>
                    </ListItemAvatar>
                    <ListItemText>
                    <TextField
                      name="name"
                      label={this.state.type === 'user' ? "User to share with" : "Group to share with"}
                      value={this.state.name}
                      onChange={this.onChange.bind(this)}
                      fullWidth
                    />
                    </ListItemText>
                    <ListItemSecondaryAction>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem> */}

                </List>
              </DialogContent>
            )
          }            

            <DialogActions>
              <Button onClick={this.props.handleClose} color="secondary">
                OK
              </Button>
          </DialogActions>

        </Dialog>
        
      </div>
    )
  }
}

SharedDialog.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default compose(
    withStyles(styles, { withTheme: true }),
    connect(
      null,
      { }
    )
  )(SharedDialog);