// Imports
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';

// UI Imports
import { Snackbar, Button, Paper, TextField, Card, CardContent } from '@material-ui/core'
import { blue, red } from '@material-ui/core/colors'

// App Imports
import { postChange } from '../../actions/user'

const styles = theme => ({
  root: {
    maxWidth: '500px',
    margin: '0 auto',
    width: '100%',
    paddingTop: theme.spacing()*4,
  },
  textField: {
    marginTop: theme.spacing()*4,
  },
});

class ChangePassword extends Component {
  constructor (props) {
    super(props)

    this.state = {
      oldpassword: '',
      newpassword: '',
      newpasswordcopy: '',
      error: '',
      isLoading: false,
      isChanging: false,
      notification: false,
      logged: false,
      display: 'content',
    }
  }

  onSubmit (event) {
    event.preventDefault()
    let input = {}
    input.oldpassword = this.state.oldpassword;
    input.newpassword = this.state.newpassword;
    input.newpasswordcopy = this.state.newpasswordcopy;

    if (input.oldpassword !== '' && input.newpassword !== '' && input.newpasswordcopy === input.newpassword ) {
      this.setState({isChanging: true, isLoading: true})

      this.props.postChange(input).then((response) => {
        console.log(response)
        if (response.success) {
          this.setState({
            isLoading: false,
            isChanging: false,
            notification: true,
            oldpassword: '',
            newpassword: '',
            newpasswordcopy: '',
            error: ''
          })

          this.setState({display:'none'})

          // Redirect
          setTimeout(() => {
            this.setState({logged: true})
          }, 1000)
        } else {
          Sentry.captureException(response.errors[0].message)
          this.setState({
            isLoading: false,
            isChanging: false,
            error: response.errors[0].message,
            notification: false
          })
        }
      })
    } else {
      this.setState({
        error: 'New Passwords don`t match.',
        notification: false
      })
    }
  }

  onChange (event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  render () {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        {this.state.error ? <Card><CardContent style={{color:red[500]}}>{this.state.error}</CardContent></Card> : ''}
        {this.state.error ? <br/> : ''}
        {this.state.message ? <Card><CardContent style={{color:blue[500]}}>{this.state.message}</CardContent></Card> : ''}
        {this.state.message ? <br/> : ''}
        <form id="form-tweet" onSubmit={this.onSubmit.bind(this)}>
          <Paper elevation={1} style={{padding: 32, display:this.state.display}} >
              <h2>Password Change</h2>

              <br/>
              <TextField
                  className={classes.textField}
                  name="oldpassword"
                  type="password"
                  label="Current Password"
                  value={this.state.oldpassword}
                  required={true}
                  onChange={this.onChange.bind(this)}
                  fullWidth
              />
              <br/>
              <TextField
                  className={classes.textField}
                  name="newpassword"
                  type="password"
                  label="New Password"
                  required={true}
                  value={this.state.newpassword}
                  onChange={this.onChange.bind(this)}
                  fullWidth
              />
              <br/>
              <TextField
                  className={classes.textField}
                  name="newpasswordcopy"
                  type="password"
                  label="Repeat new Password"
                  required={true}
                  value={this.state.newpasswordcopy}
                  onChange={this.onChange.bind(this)}
                  fullWidth
              />
              <br/>
              <br/>
              <br/>
              <br/>
              <Button variant="contained" type="submit" color="primary">Change Password</Button>
          </Paper>
        </form>

        <Snackbar
          open={this.state.isChanging}
          message="Changing Password..."
          autoHideDuration={1000}
        />

        <Snackbar
          open={this.state.notification}
          message="Password correctly changed, redirecting..."
          autoHideDuration={2000}
        />

        {this.state.logged ? <Redirect to="/"/> : ''}
      </div>
    )
  }
}

ChangePassword.propTypes = {
  postChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

ChangePassword.contextTypes = {
  router: PropTypes.object.isRequired
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(null, {postChange})
)(ChangePassword);
