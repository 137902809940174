// Imports
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';

// UI Imports
import {Button, TextField} from '@material-ui/core'
// import { blue, red } from '@material-ui/core/colors'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
// App Imports
import MySnackbarContentWrapper from '../MySnackbarContent'
import Loading from '../Loading'
// import { postRegister } from '../../actions/user'
//import { postEdited } from '../../actions/user'
import axios from "axios";
import * as Sentry from '@sentry/browser';

const styles = theme => ({
  root: {
    maxWidth: '500px',
    margin: '0 auto',
    width: '100%',
    paddingTop: '20px',
    //backgroundColor: theme.palette.background.paper,
  },
  textField: {
    marginTop: 20,
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
});



class AddGroupDialog extends Component {
  state = {
    groupName: '',
    description: '',

    error: '',
    display: 'content',
  }

  componentDidMount() {
  
  }

  onSubmit (event) {
    event.preventDefault();

    let input = {};
    input.groupName = this.state.groupName;
    input.description = this.state.description;
    input.users = [];
    input.global = [];
    input.continents = [];
    input.countries = [];
    input.cities = [];
    input.restaurants = [];
    
    if (input.groupName !== "") {

      axios.post('/groups/add',input)
        .then(response => {
          console.log("AXIOS GROUP/ADD RESPONSE: ", response)
          if (response.data.success) {
            this.setState({
              isLoading: false,
              groupName: "",
              description: "",
            });

            this.props.handleSnackbarOpen("success", "New group added correctly")
            //this.props.reloadParent()
            this.props.handleCloseDialog()

          } else {
            Sentry.captureException(response.data.error.message)
            this.setState({
              isLoading: false,
              snackbarOpen: true,
              snackbarMessage: response.data.error.message,
              snackbarType: 'error',
            })
          }
        })
        .catch(error => {
          Sentry.captureException(error)
          this.setState({
            isLoading: false,
            snackbarOpen: true,
            snackbarMessage: 'There was a problem connecting to the server. Please try again',
            snackbarType: 'error',
          })
        })
    } else {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: 'Please, fill the required fields.',
        snackbarType: 'error',
      });
    }
  }

  onChange (event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSnackbarClose = () => {
    this.setState({snackbarOpen: false})
  }

  render () {
    const { classes } = this.props

    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleCloseDialog}
          //maxWidth="xl"
          fullWidth={true}
        >
          <DialogTitle>ADD GROUP</DialogTitle>

          {
            this.state.isLoading ? 
            (
              <div style={{padding: "40px 40px 40px 40px"}}>
                <Loading color="#EF653A"/>
              </div>
            ) : (
                <form id="form-tweet" onSubmit={this.onSubmit.bind(this)}>
                <DialogContent>
                  <TextField
                    className={classes.textField}
                    name="groupName"
                    label="Group name"
                    value={this.state.groupName}
                    required={true}
                    onChange={this.onChange.bind(this)}
                    fullWidth
                  />
                  {/* <TextField
                    className={classes.textField}
                    name="description"
                    label="Description"
                    value={this.state.description}
                    required={true}
                    onChange={this.onChange.bind(this)}
                    multiline
                    fullWidth
                  /> */}
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.props.handleCloseDialog} color="secondary">
                    Cancelar
                  </Button>
                  <Button type="submit" color="secondary" autoFocus>
                    Aceptar
                  </Button>
                </DialogActions>
                </form>
              )
          }
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={this.state.snackbarOpen}
            autoHideDuration={6000}
            onClose={this.handleSnackbarClose}
          >
            <MySnackbarContentWrapper
              onClose={this.handleSnackbarClose}
              variant={this.state.snackbarType}
              message={this.state.snackbarMessage}
            />
          </Snackbar>

        </Dialog>
      </div>
    )
  }
}

AddGroupDialog.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(AddGroupDialog);