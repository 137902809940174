import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';

// App Imports
import Loading from '../Loading'
import UserList from './UserList'
import axios from "axios";
import MySnackbarContentWrapper from '../MySnackbarContent'
import Snackbar from '@material-ui/core/Snackbar';

// import Register from './Register'

class ControlAccesoListContainer extends Component {

  state = {
    users: [],
    loading: true,
  }

  componentDidMount () {
    this.getUsers()
  }

  reloadParent = () => {
    this.setState({loading:true})
    console.log("RELOAD PARENT")
    this.getUsers()    
  }

  getUsers(){
    axios
      .get("/users")
      .then(response => {
        console.log("response users: ", response)
        if(response.data.success){
          this.setState({
            users: response.data.data,
            loading: false,
          })
        } else{
          console.log("GROUP ERROR 1: ", response.data.error.message)
          this.handleSnackbarOpen('error', 'There was a problem connecting to the server. Please try again')
        }
      })
      .catch(error => {
        console.log("GROUP ERROR 2: ", error)
        Sentry.captureException(error)
        this.handleSnackbarOpen('error', error.message)
      })
  }

  handleSnackbarOpen = (type, message) => {
    //console.log("PARENT SNACKBAR OPEN")
    this.setState({
      snackbarMessage: message,
      snackbarType: type,
      snackbarOpen: true,
    })
  }

  handleSnackbarClose = () => {
    this.setState({snackbarOpen: false})
  }

  render () {
    return (
      <div>
        {this.state.loading ? <Loading/> : <UserList 
                                              user={this.props.user} 
                                              users={this.state.users}
                                              handleSnackbarOpen={this.handleSnackbarOpen}
                                              reloadParent={this.reloadParent}
                                              />}

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.handleSnackbarClose}
            variant={this.state.snackbarType}
            message={this.state.snackbarMessage}
          />
        </Snackbar>
      </div>
    )
  }
}

ControlAccesoListContainer.propTypes = {
  user: PropTypes.object.isRequired,
}

function controlAccesoListState (state) {
  return {
    user: state.user,
  }
}


export default connect(controlAccesoListState, {})(ControlAccesoListContainer);
