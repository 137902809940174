import React, {Component} from "react";
import { connect } from 'react-redux'
import { compose } from 'recompose'
import axios from "axios";
import * as Sentry from '@sentry/browser';

import PropTypes from "prop-types";
import CustomToolbar from "../CustomToolbar";
import AddUserDialog from "./AddUserDialog";
import EditUserDialog from "./EditUserDialog";
import PermissionsUserDialog from "./PermissionsUserDialog";
//import { deleteUsers } from '../../actions/user'

//UI
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from "@material-ui/icons/Delete";

//ACTIONS
//import { postDelete } from '../../actions/user'

const styles = theme => ({
  main: {
    //margin: theme.spacing() * 2,
    overflowY: 'scroll',
    maxHeight: 'calc(100vh - 162px)'
  },
  root: {
    width: "100%",
    //height: "100%"
  },
  iconButton: {

  },
  deleteIcon: {
    marginRight: "20px",
  },
  groupsButton: {
    background: "none",
    color: "inherit",
    border: "none",
    padding: "0",
    font: "inherit",
    cursor: "pointer",
    outline: "inherit",
    textAlign: "left !important",
  },
  allLabel: {
    background: "none",
    color: "inherit",
    border: "none",
    padding: "0",
    font: "inherit",
    outline: "inherit",
  }
});

class CustomToolbarSelect extends React.Component {

  handleClick = () => {
    console.log("click! current selected rows", this.props.selectedRows);
    this.props.handleOpenDeleteDialog(this.props.selectedRows);

  };

  render() {

    return (
      <Tooltip style={{marginRight: "20px"}} title={"Delete users selected"}>
        <IconButton onClick={this.handleClick}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    );
  }
}

class GestionUsuariosTable extends Component {

  state = {
    openAddDialog: false,
    openEditDialog: false,
    openDeleteDialog: false,
    dataTableUsers: [],
    rowsSelected: undefined,

    openPermissionsDialog: false,
  }

  componentDidMount(){
    console.log("USERS", this.props.users)

    let data =  [];
    this.props.users.forEach((user) => {
      data.push([
        user.firstName,
        user.lastName,
        user.email,
        user.role,
        user.groups,
        user.global,
        user.continents,
        user.countries,
        user.cities,
        user.restaurants,
        user.id,
      ])
    })
    this.setState({
      dataTableUsers: data,
    })
  }

  handleOpenAddDialog = () => {
    this.setState({
      openAddDialog: true
    })
  }

  handleCloseDialog = () => {
    this.setState({
      openAddDialog: false,
      openEditDialog: false,
      openDeleteDialog: false,
      openPermissionsDialog: false,
    })
  }
  

  handleOpenEditDialog = (userId) => {
    const userAux = this.props.users.find(user => user.id === userId.currentTarget.value);
    console.log("USER TO EDIT: ", userAux)
    this.setState({ openEditDialog: true, user_to_edit: userAux});
  };

  handleOpenDeleteDialog = (selectedRows) => {
    console.log("SELECTED ROWS: ", selectedRows.data)

    let users_ids_to_delete = [];

    selectedRows.data.forEach(row => {
      console.log("SELECTED ROW DATA: ", this.props.users[row.dataIndex])
      users_ids_to_delete.push(this.props.users[row.dataIndex].id)
    })
    console.log("IDS: ", users_ids_to_delete)

    axios.post('user/delete_list', JSON.stringify(users_ids_to_delete))
      .then(response => {
        if (response.success) {

          console.log("SUCCESS DELETING")
          this.props.reloadParent();
          
          if(users_ids_to_delete.length === 1){
            this.props.handleSnackbarOpen("success", "1 user deleted correctly")
          } else {
            this.props.handleSnackbarOpen("success", users_ids_to_delete.length + " users deleted correctly")
          }
          
        } else {
          console.log("ERROR DELETING")
          this.props.handleSnackbarOpen("error", response.error.message)
        }
      })
      .catch(error => {
        console.log("ERROR deleting user list: ", error)
        Sentry.captureException(error)
        this.props.handleSnackbarOpen("error", "An error has ocurred. Please, try again.")
      })
  }

  handleDeleteUser = (event) => {
    const user_id_aux = event.currentTarget.value
    console.log("ID: ", user_id_aux)

    axios.post('user/delete_one', {id: user_id_aux})
      .then(response => {
        if (response.data.success) {

          console.log("SUCCESS DELETING")
          this.props.reloadParent();
          this.props.handleSnackbarOpen("success", "User deleted correctly")
          
        } else {
          console.log("ERROR DELETING")
          this.props.handleSnackbarOpen("error", response.error.message)
        }
      })
      .catch(error => {
        console.log("ERROR deleting user list: ", error)
        Sentry.captureException(error)
        this.props.handleSnackbarOpen("error", "An error has ocurred. Please, try again.")
      })
  }

  flushRowsSelected = () => {
    this.setState({ rowsSelected: null }, () =>
      this.setState({ rowsSelected: undefined })
    );
  };

  handleOpenPermissionsUserDialog = (permissionType, list, user) => {

    // console.log("handleOpenPermissionsUserDialog")
    // console.log("permissionType: ", permissionType)
    // console.log("list: ", list)
    // console.log("user: ", user)
    

    this.setState({
      openPermissionsDialog: true,
      permissionType: permissionType,
      permissionList: list ? list : [],
      user_to_edit_permissions: user,
    })
    
    // const permissions = this.props.users[user.currentTarget.value].permissions;
    // console.log("PERMISSIONS: ", permissions)
    // this.setState({ openPermissionsDialog: true, user_permissions: permissions});
  };

  permissionsCell = (permissionType, list, user) => {

    if(list && list.length>0){
      return(
        <button 
          className={this.props.classes.groupsButton} 
          onClick={()=>{this.handleOpenPermissionsUserDialog(permissionType, list, user)}}>
          {
            <>
              {
                list.map(permission => (
                  <div key={permissionType === 'groups' ? permission : permission.id} style={{whiteSpace: "nowrap"}}>{permissionType === 'groups' ? permission : permission.name}<br/></div>
                ))
              }
            </>
          }
        </button>
      )
    } else {
      return(
        <button 
          className={this.props.classes.groupsButton} 
          onClick={()=>{this.handleOpenPermissionsUserDialog(permissionType, list, user)}}> None </button>
      )
    }

  }

  render(){

    const { classes } = this.props;

    const options = {
      filterType: 'checkbox',
      fixedHeader: true,
      fixedSelectColumn: true,
      responsive: 'standard',
      download: false,
      print: false,
      filter: false,
      viewColumns: false,
      pagination: false,
      selectableRows: 'none',
      //pagination: false
      textLabels: {
        body: {
          noMatch: "Sorry, no matching records found",
          toolTip: "Sort",
        },
        pagination: {
          next: "Next Page",
          previous: "Previous Page",
          rowsPerPage: "Rows per page:",
          displayRows: "of",
        },
        toolbar: {
          search: "Search",
          downloadCsv: "Download CSV",
          print: "Print",
          viewColumns: "View Columns",
          filterTable: "Filter Table",
        },
        filter: {
          all: "All",
          title: "FILTERS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Show Columns",
          titleAria: "Show/Hide Table Columns",
        },
        selectedRows: {
          text: "user(s) selected",
          delete: "Delete",
          deleteAria: "Delete Selected Users",
        },
      },
      customToolbar: () => {
        return (
          <CustomToolbar tooltipTitle="Add user" handleClick={this.handleOpenAddDialog}/>
        );
      },
      customToolbarSelect: selectedRows => (
        <CustomToolbarSelect
          selectedRows={selectedRows}
          flushRowsSelected={this.flushRowsSelected}
          handleOpenDeleteDialog={this.handleOpenDeleteDialog}
        />
      )
    };

    const columns = [
      {
       name: "First name",
       options: {
        filter: false,
        sort: true,
       }
      },
      {
       name: "Last name",
       options: {
        filter: false,
        sort: true,
       }
      },
      {
        name: "Email",
        options: {
         filter: false,
         sort: true,
        }
       },
       {
        name: "Role",
        options: {
         filter: false,
         sort: true,
        }
       },
       {
        name: "Groups",
        options: {
         filter: false,
         sort: false,
         customBodyRender: (value, tableMeta, updateValue) => {
            return(
              <>
                {this.permissionsCell("groups", value, tableMeta.rowData)}
              </>
            )
          }
        }
       },
       {
        name: "Home",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            if(tableMeta.rowData[3]==="Administrator"){
              return(
                <div className={this.props.classes.allLabel} >
                  All
                </div>
              )
            } else {
              return(
                <>
                  {this.permissionsCell("global", value, tableMeta.rowData)}
                </>
              )
            }
          }
        }
       },
       {
        name: "Continents",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            if(tableMeta.rowData[3]==="Administrator"){
              return(
                <div className={this.props.classes.allLabel} >
                  All
                </div>
              )
            } else {
              return(
                <>
                  {this.permissionsCell("continents", value, tableMeta.rowData)}
                </>
              )
            }
          }
        }
       },
       {
        name: "Countries",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            if(tableMeta.rowData[3]==="Administrator"){
              return(
                <div className={this.props.classes.allLabel} >
                  All
                </div>
              )
            } else {
              return(
                <>
                  {this.permissionsCell("countries", value, tableMeta.rowData)}
                </>
              )
            }
          }
        }
       },
       {
        name: "Cities",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            if(tableMeta.rowData[3]==="Administrator"){
              return(
                <div className={this.props.classes.allLabel} >
                  All
                </div>
              )
            } else {
              return(
                <>
                  {this.permissionsCell("cities", value, tableMeta.rowData)}
                </>
              )
            }
          }
        }
       },
       {
        name: "Restaurants",
        options: {
         filter: false,
         sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            if(tableMeta.rowData[3]==="Administrator"){
              return(
                <div className={this.props.classes.allLabel} >
                  All
                </div>
              )
            } else {
              return(
                <>
                  {this.permissionsCell("restaurants", value, tableMeta.rowData)}
                </>
              )
            }
          }
        }
       },
       {
        name: "",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            //console.log("tableMeta edit: ", tableMeta)
            return(
              <>
                <Tooltip title="Edit user information">
                  <IconButton value={value} onClick={this.handleOpenEditDialog}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete user">
                  <IconButton value={value} onClick={this.handleDeleteUser}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>

              </>
            )
          }
        }
      },
     ];

    return (
      <div className={classes.main}>
         <MUIDataTable
          title={"USER MANAGEMENT"}
          data={this.state.dataTableUsers}
          columns={columns}
          options={options}
        />
        <AddUserDialog
          open={this.state.openAddDialog}
          handleCloseDialog={this.handleCloseDialog}
          handleSnackbarOpen={this.props.handleSnackbarOpen}
          reloadParent={this.props.reloadParent}
          />
          {
            this.state.openEditDialog ? (  
            <EditUserDialog
              open={this.state.openEditDialog}
              handleCloseDialog={this.handleCloseDialog}
              handleSnackbarOpen={this.props.handleSnackbarOpen}
              reloadParent={this.props.reloadParent}
              user_to_edit={this.state.user_to_edit}
            />  ) : null
          }
          {
            this.state.openPermissionsDialog ? (  
            <PermissionsUserDialog
              open={this.state.openPermissionsDialog}
              handleCloseDialog={this.handleCloseDialog}
              handleSnackbarOpen={this.props.handleSnackbarOpen}
              reloadParent={this.props.reloadParent}
              permissionType={this.state.permissionType}
              permissionList={this.state.permissionList}
              user_to_edit_permissions={this.state.user_to_edit_permissions}
            />  ) : null
          }
      </div>
    );
  }
}

GestionUsuariosTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(null, {})
)(GestionUsuariosTable);