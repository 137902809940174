// Imports
import { connect } from 'react-redux'

// UI Imports
//import { MenuItem, IconButton } from '@material-ui/core'
//import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert'
//import MoreVertIcon from '@material-ui/icons/MoreVert'

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import Button from '@material-ui/core/Button';

// App Imports
import { userLogout } from '../../../actions/user'

const styles = theme => ({
  button: {
    margin: theme.spacing(),
    boxShadow: 'none',
    backgroundColor:"#EF653A",
  },
  input: {
    display: 'none',
  },
});

class UserButtonLogged extends Component {
  constructor (props) {
    super(props)
      
    this.state = {
      notification: false,
      loggedOut: false
    }
  }

  logout (event) {
    event.preventDefault()

    this.props.userLogout()
  }

  render () {
    const { classes } = this.props
    return (
       <Button variant="contained" onClick={this.logout.bind(this)} className={classes.button}>
          Sign out
       </Button>      
    )
  }
}

UserButtonLogged.propTypes = {
  classes: PropTypes.object.isRequired,
  userLogout: PropTypes.func.isRequired,
}

export default compose(
  withStyles(styles),
  connect(null, {userLogout})
)(UserButtonLogged);
