// Imports
import isEmpty from 'lodash/isEmpty'
import update from 'immutability-helper'

// App Imports
import { USER_CURRENT_SET, FETCH_USERS_BEGIN, SET_USERS } from '../actions/user'

const initialState = {
  isAuthenticated: false,
  user: {}
}

export function users (state = {list: [], error: false, loading: false}, action = {}) {
  switch (action.type) {

    case FETCH_USERS_BEGIN:
      return update(state, {
        $merge: {
          list: [],
          error: false,
          loading: true
        }
      })

    case SET_USERS:
      return update(state, {
        $merge: {
          list: action.users,
          error: false,
          loading: false
        }
      })

    default:
      return state
  }
}

export function user (state = initialState, action = {}) {
  switch (action.type) {
    case USER_CURRENT_SET:
      return {
        isAuthenticated: !isEmpty(action.user),
        user: action.user
      }

    default:
      return state
  }
}
