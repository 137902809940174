// Imports
import React, { Component } from "react";
// import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";

// UI Imports
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import KeyIcon from "@material-ui/icons/VpnKey";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Img from "react-image";
import HomeIcon from "@material-ui/icons/Home";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import GroupIcon from "@material-ui/icons/Group";
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
// import VpnKeyIcon from "@material-ui/icons/VpnKey";
// import PersonAddIcon from "@material-ui/icons/PersonAdd";
import MailIcon from "@material-ui/icons/Email";
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';

import axios from "axios";

//import UserButtonLogin from "./user/button/login";
import UserButtonLogged from "./user/button/logged";
import MySnackbarContentWrapper from './MySnackbarContent'

const drawerWidth = 300;

const styles = theme => {
  console.log(theme.mixins.toolbar);
  return {
    root: {
      height: "100%",
      //backgroundColor: theme.palette.background.default
      backgroundColor: "#EF653A"
    },
    flex: {
      flexGrow: 1
    },
    appFrame: {
      overflow: "auto",
      zIndex: 1,
      position: "relative",
      display: "flex",
      width: "100%",
      height: "100vh"
    },
    appBar: {
      position: "absolute",
      height: "100%",
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    navigationButton:{
      width:"auto",
      //margin:"0px 20px 0px 20px",
      borderBottom:"solid 1px #EF653A",
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    "appBarShift-left": {
      marginLeft: drawerWidth
    },
    "appBarShift-right": {
      marginRight: drawerWidth
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 20
    },
    listText:{
      color:"#EF653A",
    },
    hide: {
      display: "none"
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      flexShrink: 0,
      width: drawerWidth,
      backgroundColor: "#211F21"
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 0px",
      ...theme.mixins.toolbar,
      borderBottom: "solid 1px #EF6532"
    },
    content: {
      flexGrow: 1,
      height: "100%",
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    main: {
      margin: 0,
      //height:`calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
      //padding: "10px",
      overflow: "auto"
    },
    noauth: {
      width: "100%",
      //paddingTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    },
    "content-left": {
      marginLeft: -drawerWidth
    },
    "content-right": {
      marginRight: -drawerWidth
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    "contentShift-left": {
      marginLeft: 0
    },
    "contentShift-right": {
      marginRight: 0
    }
  };
};

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      new_password: '',
      new_password_repeated: '',
      openChangePasswordDialog: false,
      snackbarOpen: false
    };
  }

  handleDrawerOpen = () => this.setState({ open: true });

  handleDrawerClose = () => this.setState({ open: false });

  handleOpenChangePasswordDialog = () => {
    this.setState({
      openChangePasswordDialog: true
    })
  }

  handleCloseChangePasswordDialog = () => {
    this.setState({
      openChangePasswordDialog: false
    })
  }

  onSubmit (event) {
    event.preventDefault();

    let input = this.props.user.user;
    input.email = this.props.user.user.email;
    input.password = this.state.new_password;
    input.password_repeated = this.state.new_password_repeated;
    input.changePassword = false;

    let changePasswordCorrect;
    
    if (
      input.firstName !== "" &&
      input.lastName !== "" &&
      input.email !== "" &&
      input.role !== "" &&
      input.password !== "" &&
      input.password_repeated !== ""
    ) {

      console.log("cambiar contraeña: SÍ")
      if(input.password !== "" && (input.password === input.password_repeated)){
        console.log("LAS CONTRASEÑAS COINCIDEN");
        changePasswordCorrect = true
        input.changePassword = true
      } else {
        console.log("Las contraseñas deben coincidir.")
        this.setState({
          isLoading: false,
          snackbarOpen: true,
          snackbarMessage: 'Passwords must match.',
          snackbarType: 'error',
        })
        changePasswordCorrect = false;
      }
      
      if(changePasswordCorrect){
        this.setState({isLoading: true})
      
        console.log("INPUT: ", input)

        axios.post('/user/edit', input)
          .then(response => {
            console.log("AXIOS USER/EDIT RESPONSE: ", response)
            if (response.data.success) {
              this.setState({
                isLoading: false,
                new_password: "",
                new_password_repeated: "",       
              });

              this.handleCloseChangePasswordDialog()
              this.setState({
                isLoading: false,
                snackbarOpen: true,
                snackbarMessage: "User edited correctly",
                snackbarType: 'success',
              })

            } else {
              console.log("ERROR: ", response.data.error.message)
              this.setState({
                isLoading: false,
                snackbarOpen: true,
                snackbarMessage: response.data.error.message,
                snackbarType: 'error',
              })
            }
          })
          .catch(error => {
            console.log("ERROR: ", error)
            this.setState({
              isLoading: false,
              snackbarOpen: true,
              snackbarMessage: 'There was a problem connecting to the server. Please try again',
              snackbarType: 'error',
            })
          })
      }
    } else {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: 'Please, fill the required fields.',
        snackbarType: 'error',
      });
    }
  }

  onChange (event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    })
  }
  render() {
    const { classes, theme } = this.props;
    const { open } = this.state;
    const { isAuthenticated } = this.props.user;

    let drawer = (
      <Drawer
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}
        className={classes.drawer}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={this.handleDrawerClose} color="secondary">
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        {/* <NavLink to="/change_password_admin" activeClassName="selected" style={{ textDecoration: 'none', color: 'unset' }} activeStyle={{ textDecoration: 'underline' }}>
          <ListItem button>
            <ListItemIcon>
              <HomeIcon/>
            </ListItemIcon>
            <ListItemText primary="Inicio" />
          </ListItem>
        </NavLink> */}
        <List component="nav">
          <ListItem button className={classes.navigationButton} component="a"
          onClick={() => {
            this.props.history.push("/")
            this.handleDrawerClose()
          }}
          >
            <ListItemIcon>
              <HomeIcon color="secondary"/>
            </ListItemIcon>
            <ListItemText primary="HOME" primaryTypographyProps={{className:classes.listText}}/>
          </ListItem>
          {/* <ListItem component="a" href="/change_password_admin">
            <ListItemIcon>
              <VpnKeyIcon />
            </ListItemIcon>
            <ListItemText primary="Change your password" />
          </ListItem> */}
          <ListItem button className={classes.navigationButton}  component="a"
            onClick={() => {
              this.props.history.push("/user_control")
              this.handleDrawerClose()
            }}
            >
            <ListItemIcon>
              <HowToRegIcon color="secondary"/>
            </ListItemIcon>
            <ListItemText primary="USERS" primaryTypographyProps={{className:classes.listText}}/>
          </ListItem>
          <ListItem button className={classes.navigationButton}  component="a"
            onClick={() => {
              this.props.history.push("/group_control")
              this.handleDrawerClose()
            }}
          >
            <ListItemIcon>
              <GroupIcon color="secondary"/>
            </ListItemIcon>
            <ListItemText primary="GROUPS" primaryTypographyProps={{className:classes.listText}}/>
          </ListItem>
          <ListItem button className={classes.navigationButton} component="a"
            onClick={() => {
              this.props.history.push("/mail_configuration")
              this.handleDrawerClose()
            }}
            >
            <ListItemIcon>
              <MailIcon color="secondary"/>
            </ListItemIcon>
            <ListItemText component="a" primary="MAIL CONFIGURATION" primaryTypographyProps={{className:classes.listText}} />
          </ListItem>

          <ListItem button className={classes.navigationButton} component="a"
            onClick={() => {
              this.props.history.push("/downloadedFiles")
              this.handleDrawerClose()
            }}
            >
            <ListItemIcon>
              <ArrowDownwardIcon color="secondary"/>
            </ListItemIcon>
            <ListItemText primary="DOWNLOADED FILES" primaryTypographyProps={{className:classes.listText}} />
          </ListItem>
          
          <ListItem button className={classes.navigationButton} component="a"
            onClick={() => {
              this.props.history.push("/sentMails")
              this.handleDrawerClose()
            }}
            >
            <ListItemIcon>
              <MailIcon color="secondary"/>
            </ListItemIcon>
            <ListItemText primary="SENT MAILS" primaryTypographyProps={{className:classes.listText}} />
          </ListItem>


        </List>
      </Drawer>
    );

    return (
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <AppBar style={{ position: "fixed", zIndex: 200 }}>
            <Toolbar>
              {isAuthenticated && this.props.user.user.role === 'Administrator' ? (
                <IconButton
                  color="secondary"
                  aria-label="Open drawer"
                  onClick={this.handleDrawerOpen}
                  className={classNames(
                    classes.menuButton,
                    open && classes.hide
                  )}
                >
                  <MenuIcon />
                </IconButton>
              ) : (
                ""
              )}
              <Img src={process.env.PUBLIC_URL + "/images/wok-to-walk-logo_stamp-orange.svg"} style={{height: "30px"}}/>
              <Typography
                variant="h4"
                color="secondary"
                noWrap
                style={{ marginLeft: "auto" }}
              >
                &nbsp;&nbsp;INTRANET
              </Typography>
              {/* <div style={{flexGrow: 1}} /> */}              
              {isAuthenticated ? (
                <>
                  <div style={{marginLeft:"auto"}}>
                    <Tooltip title="Change your password">
                      <IconButton onClick={this.handleOpenChangePasswordDialog} color="secondary">
                        <KeyIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div>
                    <UserButtonLogged />
                  </div>
                </>
              ) : null}
            </Toolbar>
          </AppBar>
          {isAuthenticated && this.props.user.user.role === 'Administrator' ? drawer : null}
          {isAuthenticated && this.props.user.user.role === 'Administrator' ? (
            <main
              className={classNames(classes.content, classes[`content-left`], {
                [classes.contentShift]: open,
                [classes[`contentShift-left`]]: open
              })}
            >
              <div className={classes.drawerHeader} />
              <div className={classes.main}>{this.props.children}</div>
            </main>
          ) : (
            <main className={classes.noauth}>
              <div className={classes.drawerHeader} />
              <div className={classes.main}>{this.props.children}</div>
            </main>
          )}
        </div>

        <Dialog
          open={this.state.openChangePasswordDialog}
          onClose={this.handleCloseChangePasswordDialog}
          fullWidth={true}
        >
          <DialogTitle>Change your password</DialogTitle>
          <form id="form-tweet" onSubmit={this.onSubmit.bind(this)}>
          <DialogContent>
            <TextField
              className={classes.textField}
              name="new_password"
              type="password"
              label="New password"
              required={true}
              value={this.state.password}
              onChange={this.onChange.bind(this)}
              fullWidth
            />
            <br/>
            <br/>
            <TextField
              className={classes.textField}
              name="new_password_repeated"
              type="password"
              label="Repeat new password"
              required={true}
              value={this.state.password_repeated}
              onChange={this.onChange.bind(this)}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseChangePasswordDialog} color="secondary">
              CANCEL
            </Button>
            <Button type="submit" color="secondary" autoFocus>
              SAVE
            </Button>
          </DialogActions>
          </form>
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.handleSnackbarClose}
            variant={this.state.snackbarType}
            message={this.state.snackbarMessage}
          />
        </Snackbar>

      </div>
    );
  }
}

Layout.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true }),
  connect(
    mapStateToProps,
    {}
  )
)(Layout);
