// Imports
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
import axios from "axios";
import * as Sentry from '@sentry/browser';

// UI Imports
import {Button, TextField} from '@material-ui/core'
// import { blue, red } from '@material-ui/core/colors'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';

// App Imports
import MySnackbarContentWrapper from '../MySnackbarContent'
import Loading from '../Loading'

const styles = theme => ({
  root: {
    maxWidth: '500px',
    margin: '0 auto',
    width: '100%',
    paddingTop: '20px',
    //backgroundColor: theme.palette.background.paper,
  },
  textField: {
    marginTop: 20,
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
});


class EditGroupDialog extends Component {
  state = {
      groupName: '',
      description: '',
  }

  componentDidMount() {
    console.log("user to edit: ", this.props.user_to_edit)
    this.setState({
      groupName: this.props.group_to_edit.groupName,
      description: this.props.group_to_edit.description,
    })
  }

  onSubmit (event) {
    event.preventDefault();

    let input = this.props.group_to_edit;
    input.groupName = this.state.groupName;
    input.description = this.state.description;
    
    if (input.groupName !== "") {
      
      this.setState({isLoading: true})
      console.log("INPUT: ", input)

      axios.post('/groups/edit', input)
        .then(response => {
          console.log("AXIOS GROUPS/EDIT RESPONSE: ", response)
          if (response.data.success) {
            this.setState({
              isLoading: false,
              groupName: "",
              description: "",
            });

            this.props.handleSnackbarOpen("success", "Group edited correctly")
            //this.props.reloadParent()
            this.props.handleCloseDialog()

          } else {
            console.log("ERROR: ", response.data.error.message)
            Sentry.captureException(response.data.error.message)
            this.setState({
              isLoading: false,
              snackbarOpen: true,
              snackbarMessage: response.data.error.message,
              snackbarType: 'error',
            })
          }
        })
        .catch(error => {
          console.log("ERROR: ", error)
          Sentry.captureException(error)
          this.setState({
            isLoading: false,
            snackbarOpen: true,
            snackbarMessage: 'There was a problem connecting to the server. Please try again',
            snackbarType: 'error',
          })
        })
      
    } else {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: 'Please, fill the required fields.',
        snackbarType: 'error',
      });
    }
  }

  onChange (event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render () {
    const { classes } = this.props

    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleCloseDialog}
          //maxWidth="xl"
          fullWidth={true}
        >
          <DialogTitle>EDIT GROUP</DialogTitle>
          {
            this.state.isLoading ? 
            (
              <div style={{padding: "40px 40px 40px 40px"}}>
                <Loading color="#EF653A"/>
              </div>              
            ) : (
              <form id="form-tweet" onSubmit={this.onSubmit.bind(this)}>
                <DialogContent>
                  <TextField
                    disabled
                    className={classes.textField}
                    name="groupName"
                    label="Group name"
                    value={this.state.groupName}
                    // required={true}
                    onChange={this.onChange.bind(this)}
                    fullWidth
                  />
                  {/* <TextField
                    className={classes.textField}
                    name="description"
                    label="Description"
                    value={this.state.description}
                    required={true}
                    onChange={this.onChange.bind(this)}
                    multiline
                    fullWidth
                  /> */}
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.props.handleCloseDialog} color="secondary">
                    Cancelar
                  </Button>
                  <Button type="submit" color="secondary" autoFocus>
                    Aceptar
                  </Button>
                </DialogActions>
              </form>
            )
          }


          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={this.state.snackbarOpen}
            autoHideDuration={6000}
            onClose={this.handleSnackbarClose}
          >
            <MySnackbarContentWrapper
              onClose={this.handleSnackbarClose}
              variant={this.state.snackbarType}
              message={this.state.snackbarMessage}
            />
          </Snackbar>

        </Dialog>
        
      </div>
    )
  }
}

EditGroupDialog.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(EditGroupDialog);