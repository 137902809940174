// Imports
import { combineReducers } from 'redux'
import { user, users } from './user'
 
// App Imports
export default combineReducers({
    //user : authReducer
    user,
    users,
});
