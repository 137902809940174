import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
//import AddIcon from "@material-ui/icons/Add";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import { withStyles } from "@material-ui/core/styles";

const defaultToolbarStyles = {
  iconButton: {
  },
};

class CustomToolbar extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Tooltip title={this.props.tooltipTitle}>
          <IconButton className={classes.iconButton} onClick={this.props.handleClick}>
            <AddCircleOutlineIcon className={classes.iconButton} />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  }

}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(CustomToolbar);