import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import ViewFile from '@material-ui/icons/RemoveRedEye';
import Checkbox from '@material-ui/core/Checkbox';
import FolderIcon from '@material-ui/icons/Folder';
import FileIcon from '@material-ui/icons/InsertDriveFileOutlined';
import GroupIcon from '@material-ui/icons/Group';
import dateFormat from 'dateformat';
import SharedDialog from './SharedDialog'
import Tooltip from '@material-ui/core/Tooltip';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';


const styles = theme => ({
  root: {
    width: '100%',
  },
  ListRootStyle:{
    backgroundColor: "white",
    padding: "0px"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  folderButton:{
      width: '100%',
      margin: theme.spacing(1),
      justifyContent: 'left',
  },
  nested: {
    marginLeft: theme.spacing(8),
    // border: "1px solid grey",
    // //border: "1px solid grey",
    // borderRadius: "3px"
  },
  infoInLine: {
    marginRight: '200px'
  },
  infoInLineMoreSpace: {
    marginRight: '248px',
  },
  infoInLineMiddle: {
    marginRight: '248px',
  },
  grow: {
    flexGrow: 1,
  },
  infoInLineToolbar: {
    marginRight: '192px',
    color: '#EF653A'
  },
  Toolbar: {
    backgroundColor: '#211F21',
  },
  ToolbarContentColor: {
    color: '#EF653A'
  },
  downloadButton:{
    backgroundColor: "#FCEA52",
  },
  listItemGrid:{
    width: "100%",
    display: "flex",
    position: "relative",
    boxSizing: "border-box",
    textAlign: "left",
    alignItems: "center",
    paddingTop: "8px",
    paddingBottom: "8px",
    justifyContent: "flex-start",
    textDecoration: "none",
  }

});

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0 || bytes === "0") return '0 Bytes';

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

class Directory extends React.Component {
  state = {
    openSharedDialog: false,
    dirSelected: {},
  };

  componentDidMount(){
    console.log("this.props.contentList", this.props.contentList)
    console.log("this.props.enableSharedDialog", this.props.enableSharedDialog)
  }

  componentDidUpdate(){
    console.log("loadingDownload: ", this.props.loadingDownload)
    console.log("this.props.contentList", this.props.contentList)
    console.log("this.props.enableSharedDialog", this.props.enableSharedDialog)
  }

  handleOpenSharedDialog = (dir) => {
    this.setState({ 
      openSharedDialog: true ,
      dirSelected: dir
    });
  }

  handleCloseSharedDialog = (folder) => {
    this.setState({ 
      openSharedDialog: false 
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>

        {/*----------------------------------------------------------------------------------*/}
        {
          this.props.contentList.length > 0 ? (
            <>
              {
                this.props.contentList.map(dir => {
                  return(
                    <div key={dir.id} style={{padding: "0 0 10px"}}>
      
                      {
                        dir.mimeType === 'application/vnd.google-apps.folder' ? (
                          <List
                            component="nav"
                            //subheader={<ListSubheader component="div">Nested List Items</ListSubheader>}
                            className={classes.ListRootStyle}
                          >
                            <ListItem 
                              button
                              onClick={()=>{this.props.changeDirectory(dir, true)}}
                              style={{height: "64px"}}
                              >
                              {/* <ListItemAvatar>
                                <Avatar style={{backgroundColor: "#616161"}}>
                                  <FolderIcon style={{color: "#FCEA52"}}/>
                                </Avatar>
                              </ListItemAvatar> */}
                              <Grid container>
                                <Grid item xs={8} className={classes.listItemGrid}>                                    
                                  <ListItemIcon>
                                    <FolderIcon color="primary"/>
                                  </ListItemIcon>
          
                                  <ListItemText primary={dir.name.toLowerCase() === 'global' ? dir.autocompleteName : dir.name} />
                                </Grid>

                                <Grid item xs={2} className={classes.listItemGrid}>
                                  <Typography variant="body1">{dateFormat(dir.modifiedTime, "dd/mm/yyyy")}</Typography>
                                </Grid>
                                <Grid item xs={2} className={classes.listItemGrid}>
                                  <Typography variant="body1">{formatBytes(dir.size)}</Typography>
                                </Grid>
                              </Grid>
      
                              <ListItemSecondaryAction>
                                {
                                  (this.props.enableSharedDialog && this.props.user.role === "Administrator" && dir.id!=="1Ecdx3pmg0QOgTMlhDm8PDOByk3eLnabX") ? (
                                    <Tooltip title="Sharing permissions">
                                      <IconButton onClick={()=>{this.handleOpenSharedDialog(dir)}}>
                                        <GroupIcon color="primary"/>
                                      </IconButton>
                                    </Tooltip>
                                  ) : null
                                }
                                <Checkbox
                                  checked={this.props.checkedFilesList.some(file=>file.id===dir.id) || this.props.checkedFilesFromParentsList.some(file=>file.id===dir.id)}
                                  disabled={this.props.checkedFilesFromParentsList.some(file=>file.id===dir.id) || dir.size===0 || this.props.loadingDownload}
                                  onChange={()=>{this.props.handleCheckedFile(dir)}}
                                />
                              </ListItemSecondaryAction>
                            </ListItem>
                          </List>
      
                        ) : (
               
                          <List
                            component="nav"
                            //subheader={<ListSubheader component="div">Nested List Items</ListSubheader>}
                            className={classes.ListRootStyle}
                          >
                            <ListItem 
                              button={false} 
                              onClick={null}
                              style={{height: "64px"}}
                              >
                                <Grid container>
                                  <Grid item xs={8} className={classes.listItemGrid}>                                    
                                    <ListItemIcon>
                                      <FileIcon color="primary"/>
                                    </ListItemIcon>
            
                                    <ListItemText primary={dir.name} />
                                  </Grid>

                                  <Grid item xs={2} className={classes.listItemGrid}>
                                    <Typography variant="body1">{dateFormat(dir.modifiedTime, "dd/mm/yyyy")}</Typography>
                                  </Grid>
                                  <Grid item xs={2} className={classes.listItemGrid}>
                                    <Typography variant="body1">{formatBytes(dir.size)}</Typography>
                                  </Grid>
                              </Grid>
                              <ListItemSecondaryAction>
                                <IconButton
                                  href={"https://drive.google.com/file/d/" + dir.id + "/view"}
                                  target="_blank"
                                  >
                                  <ViewFile/>
                                </IconButton>
                                  <Checkbox
                                    checked={this.props.checkedFilesList.some(file=>file.id===dir.id) || this.props.checkedFilesFromParentsList.some(file=>file.id===dir.id)}
                                    disabled={this.props.checkedFilesFromParentsList.some(file=>file.id===dir.id) || dir.size===0 || this.props.loadingDownload}
                                    onChange={()=>{this.props.handleCheckedFile(dir)}}
                                    // value={dir.id}
                                  />
                              </ListItemSecondaryAction>
                            </ListItem>
                          </List>
                        )
                      }
      
                      {/* <br/> */}
                    </div>
                  )
                })
              }
            </>
          ) : (
            <div style={{textAlign: "center"}}>
              <img alt="not found data in this directory" src="/images/notDataFound.png"/>
            </div>
          )
        }
        { this.state.openSharedDialog ? (
          <SharedDialog 
          open={this.state.openSharedDialog}
          dir={this.state.dirSelected}
          handleClose={this.handleCloseSharedDialog}
          />
        ) : null}
      </div>
    );
  }
}

Directory.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Directory);