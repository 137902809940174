import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import * as Sentry from '@sentry/browser';
import axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


  const styles = theme => ({
    table: {
        minWidth: 650,
        overflowY: "scroll",
        maxHeight: "calc(100vh -162px)"
      },
    head: {
        lineHeight: "3rem",
        fontWeight: "1000",
        fontSize: "1.25rem",
        align: "left"
      },
    row: {
      lineHeight: "3rem",
      align: "left"
    }
  });


  function createData(subject, htmlContent, savedAt) {
    return { subject, htmlContent, savedAt };
  }
  

  class sentMails extends React.Component {

    state={
      rows: [],
      htmlContent: "",
      savedAt: null,
      openInfo: false,
      changes: false,
      openPreview: false,
    }
  
    componentDidMount(){
      this.getSentMails()
    }

    getSentMails = () => {
        axios
        .get("/email/getSentMails")
        .then(response => {
          if(response.data.success){
              let arrayRows = []
              response.data.data.forEach(r =>{
                var date = new Date(r.savedAt).toString()

                  arrayRows.push(createData(r.subject, r.htmlContent, date))
              })
              this.setState(
                this.state.rows = arrayRows
            )
 
          } else {
            Sentry.captureException(response.data.error.message)
            this.setState({
              isLoading: false,
              snackbarOpen: true,
              snackbarMessage: response.data.error.message,
              snackbarType: 'error',
            })
          }
        })
        .catch(error => {
          console.log("ERROR obteniendo los emails enviados: ", error)
          Sentry.captureException(error)
        })
      }
  
    
  
    render() {
        const { classes } = this.props;
  
      return (
        <div>
            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.head} >Subject</TableCell>
                            <TableCell className={classes.head} >HtmlContent</TableCell>
                            <TableCell className={classes.head} >SaveAt</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {this.state.rows.map((row) => (
                        <TableRow key={row.subject}>
                            <TableCell className={classes.row}>{row.subject}</TableCell>
                            <TableCell className={classes.row}>{row.htmlContent}</TableCell>
                            <TableCell className={classes.row}>{row.savedAt}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>       
        </div>
      );
    }
  }
  
  
  function appState (state) {
    return {
      user: state.user,
    }
  }
  
  export default compose(
    withStyles(styles),
    connect(
      appState,
      null
    )
  )(sentMails);