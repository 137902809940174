// Imports
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import jwtDecode from "jwt-decode";
import { setCurrentUser } from "./actions/user";
import rootReducer from "./reducers";
import App from './App';
import "./index.css";
import * as Sentry from '@sentry/browser';

Sentry.init({ dsn: 'http://acbcb49f627744bb9eb9dac5e5765c8c@35.190.163.116:9000/38'});


const theme = createMuiTheme({
  palette: {
    primary: {
      main:'#211F21',
    },
    secondary: {
      main:'#EF653A',
    },
    error: {
      main: '#ff0000',
    },
  },
  typography: { useNextVariants: true,fontFamily: ['VanCondensed'].join(',') },
  overrides: {
    MUIDataTableToolbar: {
      icon: {
        '&:hover': {
          color: '#f2835a',
        },
      },
      iconActive: {
          color: '#EF6532',
      },
    },
    MUIDataTableFilter: {
      checkbox: {
          '&$checked': {
            color: '#EF653A',
          },
      },
    },
    MUIDataTableSelectCell: {
      checked: {color: "#EF6532 !important" },
    },
    MuiTableCell:{
      root:{
        padding: '4px 10px 4px 40px',
        //textAlign: 'center',
      }
    }
  },
});


const store = createStore(
  rootReducer,

  composeWithDevTools(applyMiddleware(thunk))
)

// User Authentication
const token = localStorage.getItem("token");
if (token && token !== "undefined" && token !== "") {
  store.dispatch(setCurrentUser(jwtDecode(token)));
}

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <Router>
        <App/>
      </Router>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById("root")
);
