import React, {Component} from "react";
import { connect } from 'react-redux'
import { compose } from 'recompose'
import axios from "axios";
import * as Sentry from '@sentry/browser';

import PropTypes from "prop-types";
import CustomToolbar from "../CustomToolbar";
import AddGroupDialog from "./AddGroupDialog";
import EditGroupDialog from "./EditGroupDialog";
import PermissionsGroupDialog from "./PermissionsGroupDialog";

//UI
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from "@material-ui/icons/Delete";
import { callbackPromise } from "nodemailer/lib/shared";

//ACTIONS

const styles = theme => ({
  main: {
    //margin: theme.spacing(2),
    overflowY: 'scroll',
    maxHeight: 'calc(100vh - 162px)'
  },
  root: {
    width: "100%",
    //height: "100%"
  },
  iconButton: {

  },
  deleteIcon: {
    marginRight: "20px",
  },
  groupsButton: {
    background: "none",
    color: "inherit",
    border: "none",
    padding: "0",
    font: "inherit",
    cursor: "pointer",
    outline: "inherit",
    textAlign: "left !important",
  }
});

class CustomToolbarSelect extends React.Component {

  handleClick = () => {
    console.log("click! current selected rows", this.props.selectedRows);
    this.props.handleOpenDeleteDialog(this.props.selectedRows);

  };

  render() {

    return (
      <Tooltip style={{marginRight: "20px"}} title={"Delete groups selected"}>
        <IconButton onClick={this.handleClick}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    );
  }
}

class GroupList extends Component {

  state = {
    openAddDialog: false,
    openEditDialog: false,
    openDeleteDialog: false,
    dataTableGroups: [],
    rowsSelected: undefined,

    openPermissionsDialog: false,
  }

  componentDidMount(){
    console.log("GROUPS", this.props.groups)

    let data =  [];
    this.props.groups.forEach((groups) => {
      data.push([
        groups.groupName,
        groups.users,
        groups.global,
        groups.continents,
        groups.countries,
        groups.cities,
        groups.restaurants,
        groups.id,
      ])
    })
    this.setState({
      dataTableGroups: data,
    })
  }

  handleOpenAddDialog = () => {
    this.setState({
      openAddDialog: true
    })
  }

  handleCloseDialog = () => {
    this.setState({
      openAddDialog: false,
      openEditDialog: false,
      openDeleteDialog: false,
      openPermissionsDialog: false,
    })
  }
  

  handleOpenEditDialog = (groupId) => {
    const groupAux = this.props.groups.find(group => group.id === groupId.currentTarget.value);
    console.log("GROUP TO EDIT: ", groupAux)
    this.setState({ openEditDialog: true, group_to_edit: groupAux});
  };

  handleOpenDeleteDialog = (selectedRows) => {
    console.log("SELECTED ROWS: ", selectedRows.data)

    let groups_ids_to_delete = [];

    selectedRows.data.forEach(row => {
      console.log("SELECTED ROW DATA: ", this.props.groups[row.dataIndex])
      groups_ids_to_delete.push(this.props.groups[row.dataIndex].id)
    })
    console.log("IDS: ", groups_ids_to_delete)

    axios.post('group/delete_list', JSON.stringify(groups_ids_to_delete))
      .then(response => {
        if (response.success) {

          console.log("SUCCESS DELETING")
          this.props.reloadParent();
          
          if(groups_ids_to_delete.length === 1){
            this.props.handleSnackbarOpen("success", "1 group deleted correctly")
          } else {
            this.props.handleSnackbarOpen("success", groups_ids_to_delete.length + " groups deleted correctly")
          }
          
        } else {
          console.log("ERROR DELETING")
          Sentry.captureException(response.error.message)
          this.props.handleSnackbarOpen("error", response.error.message)
        }
      })
      .catch(error => {
        console.log("ERROR deleting group list: ", error)
        Sentry.captureException(error)
        this.props.handleSnackbarOpen("error", "An error has ocurred. Please, try again.")
      })
  }

  handleDeleteGroup = (event) => {
    const groupName_aux = event.currentTarget.value
    console.log("groupName to delete: ", groupName_aux)

    axios.post('groups/delete_one', {groupName: groupName_aux})
      .then(response => {
        if (response.data.success) {

          console.log("SUCCESS DELETING")
          this.props.reloadParent();
          this.props.handleSnackbarOpen("success", "Group deleted correctly")
          
        } else {
          console.log("ERROR DELETING")
          Sentry.captureException(response.error.message)
          this.props.handleSnackbarOpen("error", response.error.message)
        }
      })
      .catch(error => {
        console.log("ERROR deleting group: ", error)
        Sentry.captureException(error)
        this.props.handleSnackbarOpen("error", "An error has ocurred. Please, try again.")
      })
  }

  flushRowsSelected = () => {
    this.setState({ rowsSelected: null }, () =>
      this.setState({ rowsSelected: undefined })
    );
  };

  handleOpenPermissionsGroupDialog = (permissionType, list, group) => {

    console.log("handleOpenPermissionsGroupDialog")
    console.log("permissionType: ", permissionType)
    console.log("list: ", list)
    console.log("group: ", group)
    

    this.setState({
      openPermissionsDialog: true,
      permissionType: permissionType,
      permissionList: list ? list : [],
      group_to_edit_permissions: group,
    })
    
    // const permissions = this.props.users[user.currentTarget.value].permissions;
    // console.log("PERMISSIONS: ", permissions)
    // this.setState({ openPermissionsDialog: true, user_permissions: permissions});
  };

  permissionsCell = (permissionType, list, group) => {

    if(list && list.length>0){
      return(
        <button 
          className={this.props.classes.groupsButton} 
          onClick={()=>{this.handleOpenPermissionsGroupDialog(permissionType, list, group)}}>
          {
            <>
              {
                list.map(permission => (
                  <div key={permissionType === 'users' ? permission : permission.id} style={{whiteSpace: "nowrap"}}>{permissionType === 'users' ? permission : permission.name}<br/></div>
                ))
              }
            </>
          }
        </button>
      )
    } else {
      return(
        <button 
          className={this.props.classes.groupsButton} 
          onClick={()=>{this.handleOpenPermissionsGroupDialog(permissionType, list, group)}}> None </button>
      )
    }

  }

  render(){

    const { classes } = this.props;


    const options = {
      filterType: 'checkbox',
      fixedHeader: true,
      fixedSelectColumn: true,
      responsive: 'standard',
      download: false,
      print: false,
      filter: false,
      viewColumns: false,
      pagination: false,
      selectableRows: 'none',
      //pagination: false
      textLabels: {
        body: {
          noMatch: "Sorry, no matching records found",
          toolTip: "Sort",
        },
        pagination: {
          next: "Next Page",
          previous: "Previous Page",
          rowsPerPage: "Rows per page:",
          displayRows: "of",
        },
        toolbar: {
          search: "Search",
          downloadCsv: "Download CSV",
          print: "Print",
          viewColumns: "View Columns",
          filterTable: "Filter Table",
        },
        filter: {
          all: "All",
          title: "FILTERS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Show Columns",
          titleAria: "Show/Hide Table Columns",
        },
        selectedRows: {
          text: "group(s) selected",
          delete: "Delete",
          deleteAria: "Delete Selected Groups",
        },
      },
      customToolbar: () => {
        return (
          <CustomToolbar tooltipTitle="Add group" handleClick={this.handleOpenAddDialog}/>
        );
      },
      customToolbarSelect: selectedRows => (
        <CustomToolbarSelect
          selectedRows={selectedRows}
          flushRowsSelected={this.flushRowsSelected}
          handleOpenDeleteDialog={this.handleOpenDeleteDialog}
        />
      )
    };

    const columns = [
      {
       name: "Name",
       options: {
        filter: false,
        sort: true,
       }
      },
      {
        name: "Users",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return(
              <>
                {this.permissionsCell("users", value, tableMeta.rowData)}
              </>
            )
          }
        }
      },
      {
        name: "Home",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return(
              <>
                {this.permissionsCell("global", value, tableMeta.rowData)}
              </>
            )
          }
        }
      },
      {
        name: "Continents",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return(
              <>
                {this.permissionsCell("continents", value, tableMeta.rowData)}
              </>
            )
          }
        }
      },
      {
        name: "Countries",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
          return(
              <>
                {this.permissionsCell("countries", value, tableMeta.rowData)}
              </>
            )
          }
        }
      },
       {
        name: "Cities",
        options: {
         filter: false,
         sort: false,
         customBodyRender: (value, tableMeta, updateValue) => {
          return(
            <>
              {this.permissionsCell("cities", value, tableMeta.rowData)}
            </>
          )
        }
        }
       },
       {
        name: "Restaurants",
        options: {
         filter: false,
         sort: false,
         customBodyRender: (value, tableMeta, updateValue) => {
          return(
            <>
              {this.permissionsCell("restaurants", value, tableMeta.rowData)}
            </>
          )
        }
        }
      },
      {
        name: "",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            //console.log("tableMeta edit: ", tableMeta)
            return(
              <>
                <Tooltip title="Edit group information">
                  <IconButton value={value} onClick={this.handleOpenEditDialog}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete group">
                  <IconButton value={value} onClick={this.handleDeleteGroup}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>

              </>
            )
          }
        }
      },
     ];

    return (
      <div className={classes.main}>
         <MUIDataTable
          title={"GROUP MANAGEMENT"}
          data={this.state.dataTableGroups}
          columns={columns}
          options={options}
        />
        <AddGroupDialog
          open={this.state.openAddDialog}
          handleCloseDialog={this.handleCloseDialog}
          handleSnackbarOpen={this.props.handleSnackbarOpen}
          reloadParent={this.props.reloadParent}
          />
          {
            this.state.openEditDialog ? (  
            <EditGroupDialog
              open={this.state.openEditDialog}
              handleCloseDialog={this.handleCloseDialog}
              handleSnackbarOpen={this.props.handleSnackbarOpen}
              reloadParent={this.props.reloadParent}
              group_to_edit={this.state.group_to_edit}
            />  ) : null
          }
          {
            this.state.openPermissionsDialog ? (  
            <PermissionsGroupDialog
              open={this.state.openPermissionsDialog}
              handleCloseDialog={this.handleCloseDialog}
              handleSnackbarOpen={this.props.handleSnackbarOpen}
              reloadParent={this.props.reloadParent}
              permissionType={this.state.permissionType}
              permissionList={this.state.permissionList}
              group_to_edit_permissions={this.state.group_to_edit_permissions}
            />  ) : null
          }
      </div>
    );
  }
}

GroupList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(null, {})
)(GroupList);