import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
//import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  root: {
    margin: 'auto',
    //marginTop: theme.spacing(3),
    width: '40px',
  },
  colorCircle: {
    color: '#FCEA52'
  }
});

function Layout(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <CircularProgress
        value={50}
        variant={"indeterminate"}
        disableShrink={true}
        style={props.color ? {color:props.color} : {color:'#FCEA52'}}
      />
    </div>
  );
}

Layout.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Layout);
