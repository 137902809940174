import React from "react";
// import axios from "axios";
// import PropTypes from "prop-types";
//--------------------------------
import { compose } from "recompose";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import axios from "axios";
import MySnackbarContentWrapper from '../MySnackbarContent';
import Snackbar from '@material-ui/core/Snackbar';
import dateFormat from 'dateformat';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import * as Sentry from '@sentry/browser';

const styles = theme => ({
  main: {
    maxWidth: '80%',
    margin: '0 auto',
    marginTop: theme.spacing()*3,
    width: '100%',
  },
  paper: {
    width: "100%",
    margin: '20px 0 20px 0',
    padding: theme.spacing()*3,
  },
  saveButton:{
    // maxWidth: '80%',
    // width: '100%',
    margin: '0 20px 0 0',
    backgroundColor: "#FCEA52",
  },
  infoButton:{
    margin: '20px 20px 0 0',
    backgroundColor: "#FCEA52",
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0 || bytes === "0") return '0 Bytes';

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

class MailConfig extends React.Component {

  state={
    subject: "",
    htmlContent: "",
    savedAt: null,
    openInfo: false,
    changes: false,
    openPreview: false,
  }

  componentDidMount(){
    this.getConfiguration()
  }

  getConfiguration = () => {
    console.log("getConfigurations")
    axios
    .get("/email/get_configurations")
    .then(response => {
      console.log("response email configuration", response)
      if(response.data.success){
        this.setState({
          subject: response.data.data.subject,
          htmlContent: response.data.data.htmlContent,
          savedAt: response.data.data.savedAt,
        })
      } else {
        Sentry.captureException(response.data.error.message)
        this.setState({
          isLoading: false,
          snackbarOpen: true,
          snackbarMessage: response.data.error.message,
          snackbarType: 'error',
        })
      }
    })
    .catch(error => {
      console.log("ERROR obteniendo la configuración de email: ", error)
      Sentry.captureException(error)
      this.setState({
        isLoading: false,
        snackbarOpen: true,
        snackbarMessage: 'There was a problem connecting to the server. Please try again',
        snackbarType: 'error',
      })
    })
  }

  
  onChange (event) {
    event.preventDefault();
    const newValue = event.target.value;
    this.setState({
      changes: true,
      [event.target.name]: newValue
    })
  }

  handleSaveChanges = () => {
    const timestamp = (new Date()).getTime()
    
    axios.post('/email/edit', {
      subject: this.state.subject,
      htmlContent: this.state.htmlContent,
      savedAt: timestamp,
    })
    .then(response => {
      console.log("EDIT EMAIL", response)
      if (response.data.success) {
        this.setState({
          savedAt: timestamp,
          isLoading: false,
          snackbarOpen: true,
          snackbarMessage: "Email configuration edited correctly",
          snackbarType: 'success',
        });

      } else {
        console.log("ERROR: ", response.data.error.message)
        Sentry.captureException(response.data.error.message)        
        this.setState({
          isLoading: false,
          snackbarOpen: true,
          snackbarMessage: response.data.error.message,
          snackbarType: 'error',
        })
      }
    })
    .catch(error => {
      console.log("ERROR: ", error)
      Sentry.captureException(error)
      this.setState({
        isLoading: false,
        snackbarOpen: true,
        snackbarMessage: 'There was a problem connecting to the server. Please try again',
        snackbarType: 'error',
      })
    })
  }

  handleSnackbarClose = () => {
    this.setState({snackbarOpen: false})
  }

  handleOpenInfo = () => {
    this.setState({openInfo: true})
  }

  handleCloseInfo = () => {
    this.setState({openInfo: false})
  }

  handleOpenPreview = () => {
    let subject_preview = this.state.subject;
    let htmlContent_preview = this.state.htmlContent

    subject_preview = subject_preview.replace("[user_firstname]", this.props.user.user.firstName)
    subject_preview = subject_preview.replace("[user_lastname]", this.props.user.user.lastName)
    subject_preview = subject_preview.replace("[user_email]", this.props.user.user.email)

    htmlContent_preview = htmlContent_preview.replace("[user_firstname]", this.props.user.user.firstName)
    htmlContent_preview = htmlContent_preview.replace("[user_lastname]", this.props.user.user.lastName)
    htmlContent_preview = htmlContent_preview.replace("[user_email]", this.props.user.user.email)

    let beforeList = htmlContent_preview.split("[start_list]")[0]
    let listElement = htmlContent_preview.split("[start_list]")[1]
    listElement = listElement.split("[end_list]")[0]
    let afterList = htmlContent_preview.split("[end_list]")[1]
    let elementsInList = []

    const docsToThisUser = [
      {name: "image1.jpg", path: "Marketing/Europe/Portugal/Global/REBRANDING Logo/SM/INSTAGRAM/CMYK/image1.jpg", size: "1797250"},
      {name: "poster_enjoy_london_594x841_A1.pdf", path: "Marketing/Europe/UK/Manchester/Global/Store Designs/Posters/poster_enjoy_london_594x841_A1.pdf", size: "3955453"}
    ]
    docsToThisUser.forEach(doc => {
      let element = listElement.replace("[file_name]", doc.name)
      element = element.replace("[file_name]", doc.name)
      element = element.replace("[file_path]", doc.path)
      element = element.replace("[file_size]", formatBytes(doc.size))
      elementsInList.push(element)
    })

    let htmlFinal = [beforeList, ...elementsInList, afterList].join('')

    this.setState({
      openPreview: true,
      subject_preview: subject_preview,
      htmlContent_preview: htmlFinal,
    })
  }

  handleClosePreview = () => {
    this.setState({openPreview: false})
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.main}>

        <Paper className={classes.paper}>
          <Typography variant="h5" component="h3">
            Mail subject
          </Typography>
          <TextField
            // style={{ margin: 8 }}
            name="subject"
            value={this.state.subject}
            onChange={this.onChange.bind(this)}
            className={classes.TextField}
            fullWidth
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Paper>

        <Paper className={classes.paper}>
          <Typography variant="h5" component="h3">
            HTML Content
          </Typography>
          <TextField
            // style={{ margin: 8 }}
            name="htmlContent"
            value={this.state.htmlContent}
            onChange={this.onChange.bind(this)}
            className={classes.TextField}
            fullWidth
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            multiline
            rows={14}
          />
        </Paper>
        <Button 
          variant="contained" 
          className={classes.saveButton} 
          onClick={this.handleSaveChanges}
          disabled={!this.state.changes}
          >
            SAVE CHANGES
        </Button>
        Last modification: {dateFormat(this.state.savedAt)} <br/>
        <Button 
          variant="contained" 
          className={classes.infoButton} 
          onClick={this.handleOpenPreview}
          // disabled={this.state.checkedFilesList.length === 0}
          >
            PREVIEW
        </Button> <br/>
        <Button 
          variant="contained" 
          className={classes.infoButton} 
          onClick={this.handleOpenInfo}
          // disabled={this.state.checkedFilesList.length === 0}
          >
            INFO
        </Button>

        <Dialog
          open={this.state.openInfo}
          onClose={this.handleCloseInfo}
        >
          <DialogTitle> How to set up the email</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography variant="h6" component="h3">
                Email subject
              </Typography>
              You can write the text you want. To add specific information of the user to whom the email is sent type 
              <b>[user_firstname]</b>, <b>[user_lastname]</b> or <b>[user_email]</b> with the square brackets wherever 
              you want this information to be placed.
              <br/>
              <br/>
              <Typography variant="h6" component="h3">
                HTML Content
              </Typography>
              Write or copy an HTML document. Here you can also add additional information of the user to whom the email 
              is sent int the place that you want using typing the elements <b>[user_firstname]</b>, <b>[user_lastname]</b> 
              or <b>[user_email]</b>. <br/>
              To indicate where the list of files will be displayed, type the elements <b>[start_list]</b> to point out the 
              beginning of the list and <b>[end_list]</b> to point out the end of the list. HTML elements between [start_list] 
              and [end_list] will be repeated for each file. Between this two elements it is also possible to write the file name 
              using <b>[file_name]</b>, the file path inside the Wok To Walk INTRANET using <b>[file_path]</b> or the file size using <b>[file_size]</b>.
              <br/>The structure of the list is as follows:<br/>
              <br/>
              [start_list]<br/>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[file_name]<br/>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[file_path]<br/>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[file_size]<br/>
              [end_list]<br/>


            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseInfo} color="primary">
              CLOSE
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog fullScreen open={this.state.openPreview} onClose={this.handleClosePreview} TransitionComponent={Transition}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={this.handleClosePreview} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {this.state.subject_preview}
              </Typography>
              <Button color="inherit" onClick={this.handleClosePreview}>
                CLOSE
              </Button>
            </Toolbar>
          </AppBar>
          <div dangerouslySetInnerHTML={{__html: this.state.htmlContent_preview}}/>
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
        >
          <MySnackbarContentWrapper
            onClose={null}
            variant={this.state.snackbarType}
            message={this.state.snackbarMessage}
          />
        </Snackbar>
      </div>
    );
  }
}

MailConfig.propTypes = {
  // user: PropTypes.object.isRequired,
  // classes: PropTypes.object.isRequired,
};

function appState (state) {
  return {
    user: state.user,
  }
}

export default compose(
  withStyles(styles),
  connect(
    appState,
    null
  )
)(MailConfig);