// Imports
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles';
import axios from "axios";

// UI Imports
import {Button, TextField, MenuItem } from '@material-ui/core'
// import { blue, red } from '@material-ui/core/colors'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import KeyIcon from "@material-ui/icons/VpnKey";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';

// App Imports
import MySnackbarContentWrapper from '../MySnackbarContent'
import Loading from '../Loading'
import { postRegister } from '../../actions/user'
//import { postEdited } from '../../actions/user'

const styles = theme => ({
  root: {
    maxWidth: '500px',
    margin: '0 auto',
    width: '100%',
    paddingTop: '20px',
    //backgroundColor: theme.palette.background.paper,
  },
  textField: {
    marginTop: 20,
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing() * 2,
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(),
  },
});

const roles = [
  {
    value: "Administrator",
    label: "Administrator",
  },
  {
    value: "User",
    label: "User",
  },
];


class EditUserDialog extends Component {
  state = {
      username: '',
      password: '',
      password_repeated: '',
      role: '',
      error: '',
      userEdited: false,
      display: 'content',
  }

  componentDidMount() {
    console.log("user to edit: ", this.props.user_to_edit)
    this.setState({
      firstName: this.props.user_to_edit.firstName,
      lastName: this.props.user_to_edit.lastName,
      email: this.props.user_to_edit.email,
      role: this.props.user_to_edit.role,
    })
  }

  onSubmit (event) {
    event.preventDefault();

    let input = this.props.user_to_edit;
    input.firstName = this.state.firstName;
    input.lastName = this.state.lastName;
    input.email = this.state.email;
    input.role = this.state.role;
    input.changePassword = false;

    let changePasswordCorrect;
    
    if (
      input.firstName !== "" &&
      input.lastName !== "" &&
      input.email !== "" &&
      input.role !== ""
    ) {

      if(this.state.boolChangePassword){
        console.log("cambiar contraeña: SÍ")
        if(this.state.password !== "" && (this.state.password === this.state.password_repeated)){
          console.log("LAS CONTRASEÑAS COINCIDEN");
          changePasswordCorrect = true
          input.changePassword = true
          input.password = this.state.password
        } else {
          console.log("Las contraseñas deben coincidir.")
          this.setState({
            isLoading: false,
            snackbarOpen: true,
            snackbarMessage: 'Passwords must match.',
            snackbarType: 'error',
          })
          changePasswordCorrect = false;
        }
      } else {
        changePasswordCorrect = true
      }
      
      if(changePasswordCorrect){
        this.setState({isLoading: true})
      
        console.log("INPUT: ", input)

        axios.post('/user/edit', input)
          .then(response => {
            console.log("AXIOS USER/EDIT RESPONSE: ", response)
            if (response.data.success) {
              this.setState({
                isLoading: false,
                password: "",
                firstName: "",
                lastName: "",
                email: "",
                role: "",
              });

              this.props.handleSnackbarOpen("success", "User edited correctly")
              //this.props.reloadParent()
              this.props.handleCloseDialog()

            } else {
              console.log("ERROR: ", response.data.error.message)
              this.setState({
                isLoading: false,
                snackbarOpen: true,
                snackbarMessage: response.data.error.message,
                snackbarType: 'error',
              })
            }
          })
          .catch(error => {
            console.log("ERROR: ", error)
            this.setState({
              isLoading: false,
              snackbarOpen: true,
              snackbarMessage: 'There was a problem connecting to the server. Please try again',
              snackbarType: 'error',
            })
          })
      }
    } else {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: 'Please, fill the required fields.',
        snackbarType: 'error',
      });
    }
  }

  onChange (event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleChangePassword = () => {
    this.setState(prevState => ({
      boolChangePassword: !prevState.boolChangePassword,
    }))
  }

  render () {
    const { classes } = this.props

    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleCloseDialog}
          //maxWidth="xl"
          fullWidth={true}
        >
          <DialogTitle>EDIT USER</DialogTitle>
          {
            this.state.isLoading ? 
            (
              <div style={{padding: "40px 40px 40px 40px"}}>
                <Loading color="#EF653A"/>
              </div>              
            ) : (
              <form id="form-tweet" onSubmit={this.onSubmit.bind(this)}>
                <DialogContent>
                  <TextField
                    className={classes.textField}
                    name="firstName"
                    label="First name"
                    value={this.state.firstName}
                    required={true}
                    onChange={this.onChange.bind(this)}
                    fullWidth
                  />
                  <TextField
                    className={classes.textField}
                    name="lastName"
                    label="Last name"
                    value={this.state.lastName}
                    required={true}
                    onChange={this.onChange.bind(this)}
                    fullWidth
                  />
                  <TextField
                    disabled
                    className={classes.textField}
                    name="email"
                    label="Email"
                    value={this.state.email}
                    // required={true}
                    onChange={this.onChange.bind(this)}
                    fullWidth
                  />
                  <TextField
                    className={classes.textField}
                    id="role"
                    select
                    label="Rol"
                    value={this.state.role}
                    onChange={this.handleChange("role")}
                    fullWidth
                    required={true}
                  >
                    {roles.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  {
                    this.state.boolChangePassword ? (
                        <>
                          <TextField
                            className={classes.textField}
                            name="password"
                            type="password"
                            label="Password"
                            required={true}
                            value={this.state.password}
                            onChange={this.onChange.bind(this)}
                            fullWidth
                          />
                          <TextField
                            className={classes.textField}
                            name="password_repeated"
                            type="password"
                            label="Repeat password"
                            required={true}
                            value={this.state.password_repeated}
                            onChange={this.onChange.bind(this)}
                            fullWidth
                          />
                        </>
                    ) : null
                  }
                </DialogContent>
                <DialogActions>
                  <Tooltip title="Change password">
                    <IconButton onClick={this.handleChangePassword}>
                      <KeyIcon />
                    </IconButton>
                  </Tooltip>
                  <Button onClick={this.props.handleCloseDialog} color="secondary">
                    CANCEL
                  </Button>
                  <Button type="submit" color="secondary" autoFocus>
                    SAVE
                  </Button>
                </DialogActions>
              </form>
            )
          }


          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={this.state.snackbarOpen}
            autoHideDuration={6000}
            onClose={this.handleSnackbarClose}
          >
            <MySnackbarContentWrapper
              onClose={this.handleSnackbarClose}
              variant={this.state.snackbarType}
              message={this.state.snackbarMessage}
            />
          </Snackbar>

        </Dialog>
        
      </div>
    )
  }
}

EditUserDialog.propTypes = {
  postRegister: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

export default compose(
    withStyles(styles, { withTheme: true }),
    connect(
      null,
      { postRegister }
    )
  )(EditUserDialog);