import React from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import Popper from '@material-ui/core/Popper';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/AddCircle";

let suggestions = [];

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          ),
        )}
      </div>
    </MenuItem>
  );
}

function getSuggestions(value) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : suggestions.filter(suggestion => {
        const keep =
          count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

const styles = theme => ({
  root: {
    // height: 250,
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    // position: 'absolute',
    zIndex: 1,
    // marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing(2),
  },
});

class AutosuggestComponent extends React.Component {
  state = {
    single: '',
    newPermission: '',
    newPermissionSelected: {},
    suggestions: [],
    enableAdd: false,
  };

  componentDidMount(){
    this.createSuggestions()
  }

  createSuggestions(){
    console.log("PROPS.LEVELS", this.props.levels)
    console.log("PROPS.USERS", this.props.users)
    console.log("PERMISSION TYPE:", this.props.permissionType)

    suggestions = []

    if(this.props.permissionType === 'users'){
      this.props.users.forEach(user => {
        suggestions.push({
          label: user.email
        })
      })
    } else { //for global - continents - countries - cities - restaurants
      this.props.levels.forEach(folder => {
        suggestions.push({
          ...folder,
          label: folder.autocompleteName, 
        })
      });
    }
  }

  checkIfEnableAdd = () =>{
    // console.log("checkIfEnableAdd")
    // console.log("this.state.newPermissionSelected", this.state.newPermissionSelected )
    if(this.state.newPermissionSelected.label === this.state.newPermission){
      this.setState({enableAdd:true})
    } else {
      this.setState({enableAdd: false})
    }
  }

  getSuggestionValue = (suggestion) => {
    // console.log("getSuggestionValue")

    console.log("suggestion selected: ", suggestion)
    this.setState({
      newPermissionSelected: suggestion,
      //enableAdd: suggestion.label === ,
    }, () => {
      // console.log("NEW STATE", this.state)
      this.checkIfEnableAdd()
    })
    return suggestion.label;
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value),
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleChange = name => (event, { newValue }) => {
    // console.log("handleChange")
    this.setState({
      [name]: newValue,
    }, ()=> {
      this.checkIfEnableAdd()
    });
  };

  onAddElement = (event) => {
    this.props.addNewPermission(this.state.newPermissionSelected)
    this.setState({
      newPermission: "",
      newPermissionSelected: {},
    })
  }

  render() {
    const { classes } = this.props;

    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue: this.getSuggestionValue,
      renderSuggestion,
    };

    return (
      <div className={classes.root}>
        <ListItemText>
          <Autosuggest
              {...autosuggestProps}
              inputProps={{
                classes,
                //label: this.props.permissionType === "groups" ? "New group name" : "New permission",
                placeholder: this.props.permissionType === "users" ? "New user email" : "New permission",
                value: this.state.newPermission,
                onChange: this.handleChange('newPermission'),
                inputRef: node => {
                  this.popperNode = node;
                },
                InputLabelProps: {
                  shrink: true,
                },
              }}
              theme={{
                container: classes.container,
                suggestionsContainerOpen: classes.suggestionsContainerOpen,
                suggestionsList: classes.suggestionsList,
                suggestion: classes.suggestion,
              }}
              renderSuggestionsContainer={options => (
                  // <Paper {...options.containerProps} square>
                  //   {options.children}
                  // </Paper>
                <Popper anchorEl={this.popperNode} style={{zIndex:"1500"}} keepMounted open={Boolean(options.children)}>
                  <Paper
                    square
                    {...options.containerProps}
                    style={{ width: this.popperNode ? this.popperNode.clientWidth : null }}
                  >
                    {options.children}
                  </Paper>
                </Popper>
                
              )}
            />
        </ListItemText>
        {
          this.state.enableAdd ? (
            <ListItemSecondaryAction>
              <IconButton onClick={this.onAddElement}>
                <AddIcon/>
              </IconButton>
            </ListItemSecondaryAction>
          ) : null
        }
      </div>
    );
  }
}

AutosuggestComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AutosuggestComponent);