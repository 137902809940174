// Imports
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';

// UI Imports
import {Button, TextField, MenuItem } from '@material-ui/core'
// import { blue, red } from '@material-ui/core/colors'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
// App Imports
import MySnackbarContentWrapper from '../MySnackbarContent'
import Loading from '../Loading'
import { postRegister } from '../../actions/user'
//import { postEdited } from '../../actions/user'
import axios from "axios";

const styles = theme => ({
  root: {
    maxWidth: '500px',
    margin: '0 auto',
    width: '100%',
    paddingTop: '20px',
    //backgroundColor: theme.palette.background.paper,
  },
  textField: {
    marginTop: 20,
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing() * 2,
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(),
  },
});

const roles = [
  {
    value: "Administrator",
    label: "Administrator",
  },
  {
    value: "User",
    label: "User",
  },
];


class AddUserDialog extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',    
    password: '',
    password_repeated: '',
    role: '',
    error: '',
    display: 'content',
  }

  componentDidMount() {
  
  }

  onSubmit (event) {
    event.preventDefault();

    let input = {};
    input.password = this.state.password;
    input.password_repeated = this.state.password_repeated;
    input.firstName = this.state.firstName;
    input.lastName = this.state.lastName;
    input.email = this.state.email;
    input.role = this.state.role;
    input.groups = [];
    input.global = [];
    input.continents = [];
    input.countries = [];
    input.cities = [];
    input.restaurants = [];
    
    if (
      input.password !== "" &&
      input.password_repeated !== "" &&
      input.firstName !== "" &&
      input.lastName !== "" &&
      input.email !== "" &&
      input.role !== ""
    ) {

      if (input.password === input.password_repeated ) {
        console.log("LAS CONTRASEÑAS COINCIDEN");
        this.setState({ isLoggingIn: true, isLoading: true });
        console.log("INPUT: ", input)

        axios.post('/user/register',input)
          .then(response => {
            console.log("AXIOS USER/REGISTER RESPONSE: ", response)
            if (response.data.success) {
              this.setState({
                isLoading: false,
                password: "",
                lastName: "",
                email: "",
                role: "",
                error: ""
              });

              this.props.handleSnackbarOpen("success", "New user added correctly")
              this.props.reloadParent()
              this.props.handleCloseDialog()
              // Redirect
              this.setState({ registered: true });
            } else {
              Sentry.captureException(response.data.error.message)
              this.setState({
                isLoading: false,
                snackbarOpen: true,
                snackbarMessage: response.data.error.message,
                snackbarType: 'error',
              })
            }
          })
          .catch(error => {
            Sentry.captureException(error)
            this.setState({
              isLoading: false,
              snackbarOpen: true,
              snackbarMessage: 'There was a problem connecting to the server. Please try again',
              snackbarType: 'error',
            })
          })

      } else {
        console.log("LAS CONTRASEÑAS NO COINCIDEN");
        this.setState({
          snackbarOpen: true,
          snackbarMessage: 'Passwords must match.',
          snackbarType: 'error',
        })
      }
    } else {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: 'Please, fill the required fields.',
        snackbarType: 'error',
      });
    }
  }

  onChange (event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSnackbarClose = () => {
    this.setState({snackbarOpen: false})
  }

  render () {
    const { classes } = this.props

    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleCloseDialog}
          //maxWidth="xl"
          fullWidth={true}
        >
          <DialogTitle>ADD USER</DialogTitle>

          {
            this.state.isLoading ? 
            (
              <div style={{padding: "40px 40px 40px 40px"}}>
                <Loading color="#EF653A"/>
              </div>
            ) : (
                <form id="form-tweet" onSubmit={this.onSubmit.bind(this)}>
                <DialogContent>
                  <TextField
                    className={classes.textField}
                    name="firstName"
                    label="First name"
                    value={this.state.firstName}
                    required={true}
                    onChange={this.onChange.bind(this)}
                    fullWidth
                  />
                  <TextField
                    className={classes.textField}
                    name="lastName"
                    label="Last name"
                    value={this.state.lastName}
                    required={true}
                    onChange={this.onChange.bind(this)}
                    fullWidth
                  />
                  <TextField
                    className={classes.textField}
                    name="email"
                    label="Email"
                    value={this.state.email}
                    required={true}
                    onChange={this.onChange.bind(this)}
                    fullWidth
                  />
                  <TextField
                    className={classes.textField}
                    id="role"
                    select
                    label="Role"
                    value={this.state.role}
                    onChange={this.handleChange("role")}
                    fullWidth
                    required={true}
                  >
                    {roles.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    className={classes.textField}
                    name="password"
                    type="password"
                    label="Password"
                    required={true}
                    value={this.state.password}
                    onChange={this.onChange.bind(this)}
                    fullWidth
                  />
                  <TextField
                    className={classes.textField}
                    name="password_repeated"
                    type="password"
                    label="Repeat password"
                    required={true}
                    value={this.state.password_repeated}
                    onChange={this.onChange.bind(this)}
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.props.handleCloseDialog} color="secondary">
                    Cancelar
                  </Button>
                  <Button type="submit" color="secondary" autoFocus>
                    Aceptar
                  </Button>
                </DialogActions>
                </form>
              )
          }
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={this.state.snackbarOpen}
            autoHideDuration={6000}
            onClose={this.handleSnackbarClose}
          >
            <MySnackbarContentWrapper
              onClose={this.handleSnackbarClose}
              variant={this.state.snackbarType}
              message={this.state.snackbarMessage}
            />
          </Snackbar>

        </Dialog>
      </div>
    )
  }
}

AddUserDialog.propTypes = {
  postRegister: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

export default compose(
    withStyles(styles, { withTheme: true }),
    connect(
      null,
      { postRegister }
    )
  )(AddUserDialog);